import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, LastBreadcrumb, Breadcrumb } from './styled';

interface IProps {
	data: any[]
}

export const BreadCrumbs = ({ data }: IProps): JSX.Element | null => {
	const { t } = useTranslation()

	const mappedBreadcrumbs = data.map((i: any, idx: number) => {

		return idx === (data.length - 1)
			? <LastBreadcrumb key={i.href}>
				{i.title}
			</LastBreadcrumb>
			: <Breadcrumb href={i.path} key={i.href}>
				{i.title}
			</Breadcrumb>

	})


	return (
		<Breadcrumbs separator="/">
			{mappedBreadcrumbs}
		</Breadcrumbs>
	)
}
