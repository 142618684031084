import * as yup from 'yup'
import { Thunk } from 'yup/es/util/types';

export const additionalItems = (isValid: boolean) => yup.object().shape({
  count: yup.number()
    .required('Field is required').default('required' as Thunk<any>),
  age: yup.number()
    .required('Field is required').default('required' as Thunk<any>),
  sex: yup.string()
    .required('Field is required').default('required' as Thunk<any>),
})
