import React from 'react';
import { ButtonItem, ButtonsContainer } from './styled';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ROUTES } from "src/constants";
import { NavLink } from "react-router-dom";

type TProps = {
	id: string
	onRemove: (id: string) => void
}

const ButtonsBlock = ({ id, onRemove }: TProps): JSX.Element => {
	const handleRemove = () => {
		onRemove(id)
	}

	return (
		<ButtonsContainer>
			<ButtonItem>
				<NavLink to={`${ROUTES.EDIT}/${id}`}>
					<EditOutlined /> 
					<span className="text">Redigera</span>
				</NavLink>
			</ButtonItem>

			<ButtonItem onClick={handleRemove}>
				<DeleteOutlined />
				<span className="text">Radera</span>
			</ButtonItem>
		</ButtonsContainer>
	)
}

export default ButtonsBlock
