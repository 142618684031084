import { combineReducers } from 'redux'
import { authReducer as auth } from './auth'
import { dailyReportReducer as dailyReport } from './dailyReport'
import { incidentReportReducer as incidentReport } from './incidentReport'
import { initiativeReportReducer as initiativeReport } from './initiativeReport'
import { commonReducer as common } from './common'

export default combineReducers({
	auth,
	dailyReport,
	incidentReport,
	initiativeReport,
	common,
})
