import styled from 'styled-components'
import { Button } from 'antd';

type TProps = {
    bgColor?: string,
    color?: string,
}

export const ButtonBordered = styled(Button)`
  border-radius: 0.5rem;
  background-color: ${({ bgColor }: TProps) => bgColor};
  border-color: ${({ color }: TProps) => color};
  color: ${({ color }: TProps) => color};
  width: 10rem;
  height: 3rem;
`

export const ButtonMain = styled(Button)`
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }: TProps) => bgColor} !important;
  color: ${({ color }: TProps) => color} !important;
  border: ${({ bgColor }: TProps) => `1px solid ${bgColor}`} !important;
  width: 10rem;
  height: 3rem;
  &:hover {
    border: ${({ bgColor }: TProps) => `1px solid ${bgColor}`};
    background-color: ${({ bgColor }: TProps) => bgColor};
    color: ${({ color }: TProps) => color};
    width: 10rem;
   }
   
   a {
    display: flex;
    align-items: center;
   }
   
   svg {
    margin-right: 5px;
   }
`

export const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: transparent;
  }
`;
