import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  DELETE_REPORT,
  GET_CLUBS_LIST,
  GET_REPORTS_LIST,
  GET_REPORT_PDF,
  setClubsList,
  setError,
  setLoading,
  setReportsList, 
  GET_REPORTS_STATISTIC, 
  setReportsStatistic, 
  setCurrentClub,
  setIsReportDeleted,
} from './actions';
import { CommonApi } from '../../api';
import { TAction } from '@types';
import { commonReducerSelector } from './selectors';
import { formatDate } from 'src/services';
import { dateCommon } from 'src/constants';

export function* watchCommon() {
  yield takeLatest(DELETE_REPORT, deleteReportAsync)
  yield takeLatest(GET_CLUBS_LIST, getClubsListAsync)
  yield takeLatest(GET_REPORTS_LIST, getReportsListAsync)
  yield takeLatest(GET_REPORTS_STATISTIC, getReportsStatisticAsync)
  yield takeLatest(GET_REPORT_PDF, getReportPdfAsync)
}


export function* getClubsListAsync() {
  yield put(setLoading(true))
  yield put(setError(null))
  const { currentClub } = yield select(commonReducerSelector)
  try {
    const { data } = yield call(CommonApi.getClubsList)
    yield put(setClubsList(data))
    if (!currentClub){
      yield put(setCurrentClub(data[0]?.municipality?.clubs[0].id))
    }
  } catch (err) {
    yield put(setError(err))
    console.log('getClubsListAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* getReportsListAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { currentPage } = yield select(commonReducerSelector)
    const { data } = yield call(CommonApi.getReportsList, { ...action.data, page: currentPage})
    yield put(setReportsList(data))
  } catch (err) {
    yield put(setError(err))
    console.log('getReportsListAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* getReportsStatisticAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))

  try {
    const { data } = yield call(CommonApi.getReportsStatistic, action.data)
    yield put(setReportsStatistic(data))
  } catch (err) {
    yield put(setError(err))
    console.log('getReportsStatisticAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* deleteReportAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    yield call(CommonApi.removeReport,  action.data)
    const { datesRange, currentClub, currentPage } = yield select(commonReducerSelector)
    const requestPayload = {
      dateFrom: formatDate(datesRange[0], dateCommon),
      dateTo: formatDate(datesRange[1], dateCommon),
      clubId: currentClub,
      page: currentPage
    }
    const { data: reportsList } = yield call(CommonApi.getReportsList, requestPayload)
    yield put(setReportsList(reportsList))
    const { data: statistics } = yield call(CommonApi.getReportsStatistic, requestPayload)
    yield put(setReportsStatistic(statistics))
    yield put(setIsReportDeleted(true));
  } catch (err) {
    yield put(setError(err))
    console.log('deleteReportAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* getReportPdfAsync() {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { datesRange, currentClub, currentPage } = yield select(commonReducerSelector)
    const requestPayload = {
      dateFrom: formatDate(datesRange[0], dateCommon),
      dateTo: formatDate(datesRange[1], dateCommon),
      page: currentPage,
      clubId: currentClub,
    }
    yield call(CommonApi.getReportPdf, requestPayload)
    // yield put(setReportsList(data))
  } catch (err) {
    yield put(setError(err))
    console.log('deleteReportAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}
