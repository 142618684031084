import React, { FunctionComponent } from 'react';
import { Home, Login, Create, Report } from '../pages';
import { HomeOutlined } from '@ant-design/icons';

export enum ROUTES {
	HOME = '/',
	LOGIN = '/login',
	CREATE = '/create',
	EDIT = '/edit',
	REPORT = '/report',
}

interface RouteConfig {
	title: string,
	url: string,
	component: FunctionComponent,
	icon?: JSX.Element,
}

export const routesConfig: Array<RouteConfig> = [
	{
		title: 'Home',
		url: ROUTES.HOME,
		component: Home,
		icon: <HomeOutlined/>,
	},
	{
		title: 'Login',
		url: ROUTES.LOGIN,
		component: Login,
		icon: <HomeOutlined/>,
	},
	{
		title: 'Create',
		url: ROUTES.CREATE,
		component: Create,
		icon: <HomeOutlined/>,
	},
	{
		title: 'DailyReport',
		url: `${ROUTES.REPORT}/:id`,
		component: Report,
	},
	{
		title: 'DailyReportEdit',
		url: `${ROUTES.EDIT}/:id`,
		component: Create,
	},
]

export const homeBreadcrumbs = [
	{
		path: ROUTES.HOME,
		title: 'Dashboard'
	},
	{
		path: '',
		title: 'Dagsrapporter'
	}
]

export const reportDetailsBreadcrumbs = [
	{
		path: ROUTES.HOME,
		title: 'Dashboard'
	},
	{
		path: ROUTES.HOME,
		title: 'Dagsrapporter'
	},
	{
		path: '',
		title: 'Granska dagsrapport'
	}
]

export const createReportBreadcrumbs = [
	{
		path: ROUTES.HOME,
		title: 'Dashboard'
	},
	{
		path: ROUTES.HOME,
		title: 'Dagsrapporter'
	},
	{
		path: ROUTES.CREATE,
		title: 'Skapa en dagsrapport'
	}
]
