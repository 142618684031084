export const SET_ERROR = '[incidentReport] DAILY_SET_ERROR'
export const SET_LOADING = '[incidentReport] DAILY_SET_LOADING'
export const ADD_INCIDENT_REPORT = '[incidentReport] ADD_INCIDENT_REPORT'
export const REMOVE_INCIDENT_REPORT = '[incidentReport] REMOVE_INCIDENT_REPORT'
export const UPDATE_INCIDENT_REPORT_DATA = '[incidentReport] UPDATE_INCIDENT_REPORT_DATA'
export const RESET_INCIDENT_REPORT_DATA = '[incidentReport] RESET_INCIDENT_REPORT_DATA'
export const GET_STAFF_LIST = '[incidentReport] GET_STAFF_LIST'
export const GET_INCIDENT_TYPES_LIST = '[incidentReport] GET_INCIDENT_TYPES_LIST'
export const SET_STAFF_LIST = '[incidentReport] SET_STAFF_LIST'
export const SET_INCIDENT_TYPES_LIST = '[incidentReport] SET_INCIDENT_TYPES_LIST'
export const SET_INCIDENT_REPORTS = '[incidentReport] SET_INCIDENT_REPORTS'

export const setLoading = (data: boolean) => ({ type: SET_LOADING, data })
export const setError = (data: null | string) => ({ type: SET_ERROR, data })
export const getStaffList = (data: string) => ({ data, type: GET_STAFF_LIST })
export const getIncidentTypesList = (data: string) => ({ data, type: GET_INCIDENT_TYPES_LIST })
export const setStaffList = (data: any) => ({ type: SET_STAFF_LIST, data })
export const setIncidentTypesList = (data: any) => ({ type: SET_INCIDENT_TYPES_LIST, data })
export const setIncidentReports = (data: any) => ({ type: SET_INCIDENT_REPORTS, data })
export const addIncidentReport = (data: { id: string }) => ({ type: ADD_INCIDENT_REPORT, data })
export const removeIncidentReport = (data: { id: string }) => ({ type: REMOVE_INCIDENT_REPORT, data })
export const updateIncidentReport = (data: { id: string, value: string, name: string }) =>
  ({ type: UPDATE_INCIDENT_REPORT_DATA, data })
export const resetIncidentReportData = () => ({ type: RESET_INCIDENT_REPORT_DATA })
