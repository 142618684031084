import { Modal } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'src/constants';

export const StyledModal = styled(Modal)`
  width: 100%;
  background-color: ${COLORS.BACKGROUND_GREY};
  border: 1px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};
  padding-bottom: 0 !important;
  
  .ant-modal-body {
    display: none;
  }
  
  .ant-modal-footer, ant-modal-header {
    border: 0
  } 
`
