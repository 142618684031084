import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { setTab } from '../../../redux/dailyReport';
import { activeTabSelector } from '../../../redux/dailyReport';
import { RadioTabs, RadioTab, EditIcon } from './styled';

const CreateFormTabs = (): JSX.Element => {
  const value = useAppSelector(activeTabSelector)
  const dispatch = useAppDispatch();

  const handleTabChange = (e: any) => {
    const tab = e.target.value
    dispatch(setTab(tab))
  }

  return (
    <>
      <RadioTabs
        defaultValue={value}
        value={value}
        buttonStyle="solid"
        onChange={handleTabChange}
      >
        <RadioTab
          value="first"
        >
          <EditIcon className="icon"/>
          Dagsrapport
        </RadioTab>

        <RadioTab
          value="second"
        >
          <EditIcon className="icon"/>
          Incidentsrapport
        </RadioTab>

        <RadioTab
          value="third"
        >
          <EditIcon className="icon"/>
          Ungdomsinitiativ
        </RadioTab>
      </RadioTabs>
    </>
  )
}

export default CreateFormTabs
