import { COLORS } from './constants/colors';
import styled from 'styled-components'

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: ${COLORS.BACKGROUND_GREY};
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1240px;
  min-height: calc(100vh - 72px);
  justify-content: center;
  align-items: flex-start;
  font-size: 24px;
  background-color: ${COLORS.BACKGROUND_GREY};
`
