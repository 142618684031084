import styled from 'styled-components'
import {COLORS} from 'src/constants';


export const StyledLogoutButton = styled('div')`
  display: flex;
  border-radius: 8px;
  cursor: pointer;
`

export const Title = styled('div')`
  display: flex;
  align-items: center;
  color: ${COLORS.MAIN_TEXT};
  font-weight: 500;
  width: 100%;
  max-width: 70px;
  margin-right: 8px;
  justify-content: space-between;
`
