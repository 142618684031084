export const passwordValidation = {
  required: true,
  min: 6,
  message: 'Wrong password format'
}

export const emailValidation =  {
  required: true,
  type: 'email',
  message: 'Wrong email format'
}
