import dotenv from 'dotenv'

const local = "http://192.168.0.103.";
const staging = "https://dev.ungdomsappen.se/";

const developmentApi = staging;

const prod = "https://admin.ungdomsappen.se/";


export let baseApiUrl: string;
if (process.env.REACT_APP_ENV === "production") {
    baseApiUrl = prod;
} else if (process.env.REACT_APP_ENV === "staging") {
    baseApiUrl = staging;
} else {
    baseApiUrl = developmentApi
}
