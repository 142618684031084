import React from 'react';
import { Date } from './styled';
import { Moment } from 'moment';

type TProps = {
	name: string;
	value?: Moment;
	handleChange: any
}

const DateField = ({ name, handleChange, value, ...props }: TProps): JSX.Element => {
	const onChange = (value: any) => {
		handleChange(name, value.format('YYYY-MM-DD'))
	}

	return (
		<Date {...props} onChange={onChange} defaultValue={value} />
	)
}

export default DateField
