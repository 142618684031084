import styled from 'styled-components'
import { COLORS } from 'src/constants';


export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${COLORS.BACKGROUND_GREY};
`

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonsWrap = styled.div`
  display: flex;
  button {
    margin-left: 0.5rem;
  }
  .ant-spin-spinning {
    display: flex;
  }
  .ant-spin-dot {
    margin-right: 4px;
  }
  .ant-spin-dot-item {
    background-color: white
  }
`

export const InfoText = styled.p`
  font-size: 1rem;
  font-weight: bold;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`

export const InfoDate = styled.div`
  color: ${COLORS.BLUE}
`
