import { httpPost, httpGet, httpPatch } from "../services";

const DailyReportApi = {
  sendDailyReport(data) {
    return httpPost("/analytics/dailyReport", data);
  },
  getReport(id) {
    return httpGet(`/analytics/dailyReport/${id}`);
  },
  editReport(report) {
    return httpPatch(`/analytics/dailyReport/${report?.id}`, report);
  },
  getDefaultReportData({ clubId, date }) {
    return httpGet(`/analytics/prefilledData/${clubId}`, { date });
  },
  downloadPdf({ clubId, date }) {
    return httpGet(`analytics/${clubId}/pdf?dateFrom=${date}&dateTo=${date}`);
  },
};

export default DailyReportApi;
