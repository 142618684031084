import React from 'react';
import {
  FieldName,
  FieldValue,
  InfoTable,
  InfoRow,
  IndentTitle,
} from './styled';
import { IIncidentReport } from '../../../redux/dailyReport';

type TProps = {
  report: IIncidentReport | null;
};

const IncidentReportInfo = ({ report }: TProps): JSX.Element => {
  return (
    <InfoTable>
      <InfoRow>
        <FieldName>Inrappirterad av:</FieldName>
        <FieldValue>
          {report?.reporter?.firstName} {report?.reporter?.lastName}
        </FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Incidentskategori:</FieldName>
        <FieldValue>{report?.type?.value}</FieldValue>
      </InfoRow>
      <div>
        <IndentTitle>Summering av incident: </IndentTitle>
      </div>
      <InfoRow>
        <FieldValue dangerouslySetInnerHTML={{ __html: report?.description || '' }} />
      </InfoRow>
      <div>
        <IndentTitle>Åtgård:</IndentTitle>
      </div>
      <InfoRow>
        <FieldValue><div dangerouslySetInnerHTML={{ __html: report?.action || '' }} /></FieldValue>
      </InfoRow>
    </InfoTable>
  );
};

export default IncidentReportInfo;
