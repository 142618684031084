import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  GET_INITIATIVE_TYPES_LIST,
  UPDATE_INITIATIVE_REPORT,
  setError,
  setLoading, updateInitiativeReportData, updateMultipleInitiativeReportFields,
} from './actions';
import { InitiativeReportApi } from 'src/api';
import { setInitiativeTypesList, updateInitiativeReport, updateMultipleReportFields } from './actions';
import { TAction } from '@types';
import { initiativeReportsSelector } from '../initiativeReport/selectors';
import { getPercents } from '../../helpers/dataFormat';
import { INITIATIVE_REPORT_FIELDS } from '../../constants/forms';
import { IInitiativeReport } from './types';

const { DATE, VISITORS, TYPE, BOYS, BOYS_PERCENTS, GIRLS, GIRLS_PERCENTS, NON_GENDER, NON_GENDER_PERCENTS, CASHIER, BALANCE, DESCRIPTION } = INITIATIVE_REPORT_FIELDS


export function* watchInitiativeReport() {
  yield takeLatest(GET_INITIATIVE_TYPES_LIST, getInitiativeTypesListAsync)
  yield takeLatest(UPDATE_INITIATIVE_REPORT, updateInitiativeReportSaga)
}

export function* getInitiativeTypesListAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { data } = yield call(InitiativeReportApi.getInitiativeTypesList, action.data)
    yield put(setInitiativeTypesList(data))
  } catch (err) {
    yield put(setError(err))
    console.log('getInitiativeTypesListAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}


export function* updateInitiativeReportSaga(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { isCounter, name, value, id } = action.data

    yield put(updateInitiativeReportData({ id, isCounter, name, value }))

    if (isCounter){
      const reports = yield select(initiativeReportsSelector)
      const currentReport = reports.find((report: IInitiativeReport) => report.id === id)
      getPercents(currentReport[VISITORS], currentReport[name])
      const percentFields = {
        id,
        [GIRLS_PERCENTS]: getPercents(currentReport[VISITORS], currentReport[GIRLS]),
        [BOYS_PERCENTS]: getPercents(currentReport[VISITORS], currentReport[BOYS]),
        [NON_GENDER_PERCENTS]: getPercents(currentReport[VISITORS], currentReport[NON_GENDER]),
      }
      yield put(updateMultipleInitiativeReportFields(percentFields))
    }
  } catch (err) {
    yield put(setError(err))
    console.log('updateDailyReportCounters ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}
