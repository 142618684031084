import React from 'react'
import { Route as ReactRouter, Switch } from 'react-router-dom'
import { routesConfig } from '../constants';
import { TNavItem } from '@types';


const mapRoute = (item: TNavItem | Array<TNavItem>): any => {
	if (Array.isArray(item)) {
		return item.map(mapRoute)
	}

	return <ReactRouter
		exact
		path={item.url}
		key={item.url}
		component={item.component}
	/>
}

const routes = routesConfig.map(mapRoute)

const AppRouter = ():JSX.Element => {
	return (
		<Switch>
			{routes}
		</Switch>
	)
}

export default AppRouter
