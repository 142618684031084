import React, { useEffect } from 'react';
import { message as AntdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { setError } from '../../../redux/common/actions';
import { useDispatch } from 'react-redux';


export const ErrorPopup = ({ error } : { error: any}): JSX.Element | null => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const errorMessage = error?.data?.message
	if (!errorMessage) return null

	useEffect(() => {
		if (errorMessage) {
			AntdMessage.error(t(errorMessage))
		}
		return () => {
			dispatch(setError(null))
		}
	}, [])

	return <div />;
}
