import {
	SET_ERROR,
	SET_LOADING,
	REMOVE_INITIATIVE_REPORT,
	ADD_INITIATIVE_REPORT,
	UPDATE_INITIATIVE_REPORT_DATA,
	SET_INITIATIVE_TYPES_LIST,
	SET_INITIATIVE_REPORTS,
	UPDATE_MULTIPLE_INITIATIVE_REPORT_FIELDS,
	RESET_INITIATIVE_REPORT_DATA,
} from './actions'
import { IInitiativeReport, TInitiativeReportReducer } from './types';
import { TAction } from '@types';
import { INITIATIVE_REPORT_FIELDS } from '../../constants/forms';

const { DATE, VISITORS, BOYS, BOYS_PERCENTS, GIRLS_PERCENTS, NON_GENDER_PERCENTS, GIRLS, PARTICIPANTS, CLUB, NON_GENDER, CASHIER, BALANCE, DESCRIPTION } = INITIATIVE_REPORT_FIELDS

export const initialInitiativeReport = {
	description: '',
	[VISITORS]: 0,
	[BOYS]: 0,
	[BOYS_PERCENTS]: 0,
	[GIRLS]: 0,
	[GIRLS_PERCENTS]: 0,
	[NON_GENDER]: 0,
	[NON_GENDER_PERCENTS]: 0,
	[PARTICIPANTS]: 0,
	[CASHIER]: '',
	[BALANCE]: '',
}

export const initialState = {
	initiativeReports: [initialInitiativeReport],
	initiativeTypesList: [],
	error: null,
	loading: false
}


export const initiativeReportReducer = (state: TInitiativeReportReducer = initialState, action: TAction) => {
	switch (action.type) {
		case SET_LOADING:
			return { ...state, loading: action.data }
		case ADD_INITIATIVE_REPORT: {
			const currentReports = state.initiativeReports || [];
			return {
				...state,
				initiativeReports: [...currentReports, action.data],
			}
		}
		case SET_INITIATIVE_REPORTS:
			return {
				...state,
				initiativeReports: action.data,
			}
		case SET_INITIATIVE_TYPES_LIST:
			return {
				...state,
				initiativeTypesList: action.data,
			}
		case REMOVE_INITIATIVE_REPORT: {
			const updatedEvents = state.initiativeReports.filter((report) => report.id !== action.data.id)
			return {
				...state,
				initiativeReports: updatedEvents,
			}
		}
		case UPDATE_INITIATIVE_REPORT_DATA: {
			const { id, name, value, isCounter } = action.data

			const updatedInitiativeReports = state.initiativeReports.map((report) => {
				const updatedReport = isCounter
					? Object.assign({ ...report }, {
						[name]: value,
						[VISITORS]: (report[VISITORS] - report[name]) + +value
					})
					: Object.assign({ ...report }, {
						[name]: value,
					})
				return report.id !== id
					? report
					: updatedReport
			})
			return {
				...state,
				initiativeReports: updatedInitiativeReports,
			}
		}
		case UPDATE_MULTIPLE_INITIATIVE_REPORT_FIELDS: {
			const newInitiativeReports = state.initiativeReports.map((report: IInitiativeReport) => {
				return report.id === action?.data?.id
					? { ...report, ...action.data }
					: report
			})
			return {
				...state,
				initiativeReports: newInitiativeReports
			}
		}
		case SET_ERROR: {
			return {
				...state,
				error: action.data,
			}
		}
		case RESET_INITIATIVE_REPORT_DATA: {
			return initialInitiativeReport
		}
		default:
			return state
	}
}
