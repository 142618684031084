import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Stub } from '../Stub';

ChartJS.register(ArcElement, Tooltip, Legend);


type TProps = {
	data: any,
}

export const DoughnutChart = ({ data }: TProps) => {
	return !data?.datasets[0]?.data?.length ? <Stub/> : <Doughnut
		data={data}
		options={{
			plugins: {
				legend: {
					position: 'right',
				},
			},
		}}
	/>;
}
