import React, { useMemo, useState } from 'react';
import { InfoWrap, BlockSubtitle, BlockTitle, Indent } from './styled';
import { deleteReport } from '../../../redux/common/actions';
import { useAppDispatch } from 'src/hooks';
import {
  IDailyReport,
  IIncidentReport,
  IOnlineEvent,
  ISocialMediaPost,
  IInitiativeReport,
} from '../../../redux/dailyReport';

import Digest from './Digest';
import OnlineEventInfo from './OnlineEventInfo';
import SocialPostInfo from './SocialPostInfo';
import IncidentReportInfo from './IncidentReportInfo';
import InitiativeReportInfo from './InitiativeReportInfo';

type TProps = {
  report: IDailyReport | null;
};

const ReportInfo = ({ report }: TProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const onRemove = () => dispatch(deleteReport(report?.id));
  const mappedOnlineEvents = useMemo(
    () =>
      report?.onlineEvents?.map((event: IOnlineEvent) => (
        <OnlineEventInfo event={event} key={event.id} />
      )),
    [report]
  );

  const mappedSocialPosts = useMemo(
    () =>
      report?.socialMediaPosts?.map((post: ISocialMediaPost) => (
        <SocialPostInfo post={post} key={post.id} />
      )),
    [report]
  );

  const mappedIncidentReports = useMemo(
    () =>
      report?.incidents?.map((report: IIncidentReport) => (
        <IncidentReportInfo report={report} key={report.id} />
      )),
    [report]
  );

  const mappedInitiativeReports = useMemo(
    () =>
      report?.initiatives?.map((report: IInitiativeReport) => {
        return (
          <InitiativeReportInfo report={report} key={report.id} />
        )
      }),
    [report]
  );

  return (
    <InfoWrap>
      <BlockTitle>{`Dagsrapport ${report?.date}`}</BlockTitle>

      <Digest report={report} />

      <BlockSubtitle>Summering av dagen</BlockSubtitle>

      <Indent>
        <div dangerouslySetInnerHTML={{ __html: report?.description }} />
      </Indent>

      <BlockSubtitle>Sociala medier inlägg</BlockSubtitle>
      {mappedSocialPosts}

      <BlockSubtitle>Evenemang</BlockSubtitle>
      {mappedOnlineEvents}

      <BlockSubtitle>Incidentsrapportering</BlockSubtitle>
      {mappedIncidentReports}

      <BlockSubtitle>Ungdomsinitiativ</BlockSubtitle>
      {mappedInitiativeReports}
    </InfoWrap>
  );
};

export default ReportInfo;
