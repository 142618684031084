import {
	SET_ERROR,
	SET_LOADING,
	REMOVE_INCIDENT_REPORT,
	ADD_INCIDENT_REPORT,
	UPDATE_INCIDENT_REPORT_DATA,
	SET_STAFF_LIST,
	SET_INCIDENT_TYPES_LIST,
	SET_INCIDENT_REPORTS,
	RESET_INCIDENT_REPORT_DATA,
} from './actions'
import { TIncidentReportReducer } from './types';
import { TAction } from '@types';
import { v4 as uuid } from 'uuid';


export const initialIncidentReport = {
	id: uuid(),
	description: '',
	reporter: undefined,
	type: undefined,
	action: '',
}

export const initialState = {
	loading: false,
	error: null,
	incidentReports: [initialIncidentReport],
	staffList: null,
	incidentTypesList: [],
}

export const incidentReportReducer = (state: TIncidentReportReducer = initialState, action: TAction) => {
	switch (action.type) {
		case SET_LOADING:
			return { ...state, loading: action.data }
		case ADD_INCIDENT_REPORT: {
			const currentReports = state.incidentReports || [];
			return {
				...state,
				incidentReports: [...currentReports, action.data],
			}
		}
		case SET_INCIDENT_REPORTS: {
			return {
				...state,
				incidentReports: action.data,
			}
		}
		case SET_STAFF_LIST:
			return {
				...state,
				staffList: action.data,
			}
		case SET_INCIDENT_TYPES_LIST: {
			return {
				...state,
				incidentTypesList: action.data,
			}
		}
		case REMOVE_INCIDENT_REPORT: {
			const incidentReports = state.incidentReports.filter((report) => report.id !== action.data.id)
			return {
				...state,
				incidentReports,
			}
		}
		case UPDATE_INCIDENT_REPORT_DATA: {
			const { id, name, value } = action.data
			const incidentReports = state.incidentReports.map((report) => {
				return report.id !== id
					? report
					: { ...report, [name]: value }
			})
			return {
				...state,
				incidentReports,
			}
		}
		case SET_ERROR: {
			return {
				...state,
				error: action.data,
			}
		}
		case RESET_INCIDENT_REPORT_DATA: {
			return initialIncidentReport;
		}
		default:
			return state
	}
}

