import React from 'react';
import { StyledModal } from './styled'

type TProps = {
	onConfirm: () => void,
	onCancel: () => void,
	title?: string,
	isVisible: boolean,
	loading: boolean,
}

export const Modal = ({
	onConfirm,
	onCancel,
	title,
	loading,
	isVisible = false,
}: TProps): JSX.Element => {
	return (
		<StyledModal
			title={title}
			visible={isVisible}
			onOk={onConfirm}
			onCancel={onCancel}
			confirmLoading={loading}
		/>
	)
}
