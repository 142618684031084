import React from "react";
import { FieldName, FieldValue, InfoTable, InfoRow } from "./styled";
import { IDailyReport } from "../../../redux/dailyReport";
import {renderPercent, renderPrice} from "../../../helpers/renderStrings";

type TProps = {
  report: IDailyReport | null;
};

const Digest = ({ report }: TProps): JSX.Element => {
  return (
    <InfoTable>
      <InfoRow>
        <FieldName>Antal besökare: </FieldName>
        <FieldValue>{`${report?.total} st`}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel killar: </FieldName>
        <FieldValue>{renderPercent(report?.boysPercents)}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel tjejer: </FieldName>
        <FieldValue>{renderPercent(report?.girlsPercents)}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel ickebinära: </FieldName>
        <FieldValue>{renderPercent(report?.nonGenderPercents)}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Belopp sålt för: </FieldName>
        <FieldValue>{renderPrice(report?.amountSold)}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Kontanter saknas: </FieldName>
        <FieldValue>{renderPrice(report?.amountInCashier)}</FieldValue>
      </InfoRow>
    </InfoTable>
  );
};

export default Digest;
