import React from 'react';
import { SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { COLORS } from 'src/constants/colors';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { ButtonBordered, ButtonContainer } from '../../common/Button/styled';
import { HeaderWrap, ButtonsWrap, Title, InfoText, TitleWrap } from './styled';
import { sendDailyReport, setIsAmountValid } from '../../../redux/dailyReport/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { dailyReportReducerSelector } from '../../../redux/dailyReport';
import { validateTotal } from '../../../helpers/counters';
import { useEditMode } from '../../../hooks/useEditMode';

const CreateReportHeader = (): JSX.Element => {
	const { newReport, currentDailyReport, prefilledData, additionalDataItems }  = useAppSelector(dailyReportReducerSelector)
	const { isEditMode } = useEditMode()
	const reportData = isEditMode ? currentDailyReport : newReport
	const dispatch = useAppDispatch()
	const onSubmit = () => {
		const isValid = validateTotal(prefilledData?.total, reportData.total, additionalDataItems)
		if (isValid) {
			dispatch(sendDailyReport({ isEditMode }))
		} else {
			dispatch(setIsAmountValid(false))
		}
	}
	return (
		<HeaderWrap>
			<TitleWrap>
				<Title>
					Skapa en dagsrapport
				</Title>
				<InfoText>
					Här skapar du en ny dagsrapport
				</InfoText>
			</TitleWrap>
			<ButtonsWrap>
				<ButtonContainer className="space">
					<ButtonBordered
						bgColor={COLORS.BACKGROUND_GREY}
						color={COLORS.BLUE}
						icon={<SyncOutlined />}
					>
						<NavLink to={ROUTES.HOME}>
							Tillbaka
						</NavLink>
					</ButtonBordered>
				</ButtonContainer>
				<ButtonBordered
					type="primary"
					icon={<SaveOutlined />}
					bgColor={COLORS.BLUE}
					onClick={onSubmit}
				>
					Spara
				</ButtonBordered>
			</ButtonsWrap>
		</HeaderWrap>
	)
}

export default CreateReportHeader
