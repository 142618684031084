import styled from 'styled-components'
import { DatePicker } from 'antd';
import { COLORS } from 'src/constants';
const { RangePicker } = DatePicker;


export const DateRange = styled(RangePicker)`
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  background-color: ${COLORS.BACKGROUND_GREY};
  border: 1px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};
  height: 3rem;
  
  &::placeholder {
    color: ${COLORS.BLUE};
    text-align: center;
  }
  
  .ant-picker-input:nth-child(2) {
      display: none;
  }
  
  .ant-picker-active-bar {
    display: none !important;
  }
  

  
  .ant-select-selection-placeholder {
    color: ${COLORS.BLUE};
    display: block !important;
    opacity: 1 !important;
    text-align: center !important;
  }
  
  .ant-select-arrow {
    color: ${COLORS.BLUE};
  }
  
  .ant-picker-active-bar {
    opacity: 0 !important;
  }
  
  .ant-select-selector-focused, .ant-select-selection-search-input {
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
`


export const StyledStub = styled('div')`
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  background-color: ${COLORS.BACKGROUND_GREY};
  border: 1px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};
  height: 3rem;
  width: 140px;
  position: relative;
  margin-top: -48px;
  z-index: 999;
`

