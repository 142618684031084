import styled from 'styled-components'
import { Input } from 'antd';
import { COLORS } from '../../../constants/colors';


export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
`

export const StyledInput = styled(Input)`
  border: none;
  border-bottom: 1.5px solid #AEB3B7;
  width: 18.25rem;
  border: ${({ isValid }: { isValid: boolean }) => !isValid ? `1px solid red` : ''}
`

export const InfoText = styled.p`
  font-size: 0.875rem;
  margin-left: 2rem;
  max-width: 90%;
  flex: 1;
`

export const StyledLabel = styled.span`
  position: absolute;
  bottom: 1.875rem;
  z-index: 2;
  font-size: 0.875rem;
  color: ${COLORS.FONT_GREY};
`
