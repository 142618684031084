import styled from 'styled-components'
import { PageHeader, Popover } from 'antd';
import { CONTENT_WIDTH } from '../../constants';
import { COLORS } from "../../constants/colors";

export const StyledHeaderTitle = styled(PageHeader)`
  color: ${COLORS.MAIN_TEXT};
  font-size: 24px;
  line-height: 24px;
`
export const StyledHeader = styled('div')`
  max-width: ${CONTENT_WIDTH};
  background-color: ${COLORS.WHITE};
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const HeaderWrap = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
`
export const StyledPopover = styled(Popover)`
  display: flex;
`

export const ProfileButton = styled('div')`
  display: flex;
  justify-content: right;
  align-items: center;
  color: ${COLORS.MAIN_TEXT};
  font-weight: 500;
  width: 100%;
  margin-right: 10px;
  max-width: 150px;
  cursor: pointer;
`
