import styled from 'styled-components'
import { COLORS } from '../../../../constants/colors';

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
`

export const InfoText = styled.p`
  font-size: 0.875rem;
  margin-left: 2rem;
  max-width: 90%;
`

export const SectionText = styled.p`
  font-size: 1.125rem;
  font-weight: bold;
  margin: 1rem 0;
`

export const TextAreaBlock = styled.div`
  width: 100%;
  margin: 2rem 0;
`

export const PostWrapper = styled.div`
  width: 100%;
`
export const StyledForm = styled.form`
  width: 100%;
`

export const DeleteBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .delete {
    color: red;
    cursor: pointer;
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    span {
      margin-left: 0.3rem;
      font-size: 1rem;
    }
  }
`

export const AddPostBlock = styled.div`
  margin: 1rem 0;
  font-size: 1rem;
  cursor: pointer;
  .icon {
    color: ${COLORS.BLUE};
    margin-right: 0.5rem;
  }
`

export default {
  AddPostBlock,
  DeleteBlock,
  InfoText,
  Row,
  SectionText,
  TextAreaBlock
}
