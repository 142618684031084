import React, { useEffect } from 'react';
import { useAppDispatch } from 'src/hooks';
import { getClubsList } from '../redux/common';
import { BreadCrumbs, DailyReport } from '../components';
import { reportDetailsBreadcrumbs } from '../constants/navigation';
import { MainContainer } from './styled';

const ReportDetails = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(getClubsList())
	}, [])

	return (
		<MainContainer>
			<BreadCrumbs data={reportDetailsBreadcrumbs}/>
			<DailyReport />
		</MainContainer>
	)}

export default ReportDetails
