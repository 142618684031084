import React from 'react';
import { FieldName, FieldValue, InfoTable, InfoRow, Indent } from './styled';
import { IOnlineEvent } from '../../../redux/dailyReport';
import { renderPercent } from '../../../helpers/renderStrings';

type TProps = {
  event: IOnlineEvent | null;
};

const OnlineEventInfo = ({ event }: TProps): JSX.Element => {
  return (
    <InfoTable>
      <InfoRow>
        <FieldName>Namn av online-evenemanget:</FieldName>
        <FieldValue>{event?.name}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Antal besökare:</FieldName>
        <FieldValue>{`${event?.participants} st`}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel killar:</FieldName>
        <FieldValue>{renderPercent(event?.boys)}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel tjejer:</FieldName>
        <FieldValue>{renderPercent(event?.girls)}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel ickebinära:</FieldName>
        <FieldValue>{renderPercent(event?.nonGender)}</FieldValue>
      </InfoRow>
      <Indent>
        <div dangerouslySetInnerHTML={{ __html: event?.description || '' }} />
      </Indent>
    </InfoTable>
  );
};

export default OnlineEventInfo;
