export const LOG_OUT = '[auth] LOG_OUT'
export const RESET_AUTH_STATE = '[auth] RESET_AUTH_STATE'
export const SET_LOADING = '[auth] SET_LOADING'
export const SET_TOKEN = '[auth] SET_TOKEN'
export const SET_ERROR = '[auth] SET_ERROR'
export const SIGN_IN = '[auth] SIGN_IN'
export const SOCIAL_SIGN_IN = '[auth] SOCIAL_SIGN_IN'
export const SIGN_UP = '[auth] SIGN_UP'
export const SET_IS_REGISTRATION_SUCCESS = '[auth] SET_IS_REGISTRATION_SUCCESS'
export const UPDATE_PASSWORD = '[auth] UPDATE_PASSWORD'
export const FORGOT_PASSWORD = '[auth] FORGOT_PASSWORD'
export const SET_IS_FORGOT_PASSWORD_SUCCESSFULLY_SENT = '[auth] SET_IS_FORGOT_PASSWORD_SUCCESSFULLY_SENT'
export const RESET_PASSWORD = '[auth] RESET_PASSWORD'
export const SET_IS_PASSWORD_UPDATED = '[auth] SET_IS_PASSWORD_UPDATED'
export const SET_PROFILE_DATA = '[auth] SET_PROFILE_DATA'

export const setLoading = (data: any) => ({ data, type: SET_LOADING })
export const setToken = (data: any) => ({ data, type: SET_TOKEN })
export const setProfileData = (data: any) => ({ data, type: SET_PROFILE_DATA })
export const logOut = () => ({ type: LOG_OUT })
export const resetAuthState = () => ({ type: RESET_AUTH_STATE })
export const setError = (data: any) => ({ data, type: SET_ERROR })
export const signIn = (data: any) => ({ data, type: SIGN_IN })
export const socialSignIn = (data: any) => ({ data, type: SOCIAL_SIGN_IN })
export const signUp = (data: any) => ({ data, type: SIGN_UP })
export const setIsRegistrationSuccess = (data: any) => ({ data, type: SET_IS_REGISTRATION_SUCCESS })
export const setIsPasswordUpdated = (data: any) => ({ data, type: SET_IS_PASSWORD_UPDATED })
export const updatePassword = (data: any) => ({ data, type: UPDATE_PASSWORD })
export const forgotPassword = (data: any) => ({ data, type: FORGOT_PASSWORD })
export const setIsForgotPasswordSuccessfullySent = (data: any) => ({ data, type: SET_IS_FORGOT_PASSWORD_SUCCESSFULLY_SENT })
export const resetPassword = (data: any) => ({ data, type: RESET_PASSWORD })
