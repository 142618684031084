import React from 'react';
import  PercentBlock  from './PercentBlock';
import ButtonsBlock from './ButtonsBlock';
import { formatDate } from '../../../services/common';
import { ItemContainer, StyledDate } from './styled';
import { ROUTES } from 'src/constants';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { setEditReportId } from '../../../redux/dailyReport/actions';
import { calcTotal } from '../../../helpers/counters';


type TProps = {
	report: any
	onRemove: (id: string) => void
}

const ReportItem = ({ report, onRemove }: TProps): JSX.Element => {
	const onClick = () => {
		setEditReportId(report.id)
	}

	return (
		<ItemContainer>
			<NavLink to={`${ROUTES.REPORT}/${report.id}`} onClick={onClick}>
				<StyledDate>
					{formatDate(moment(report.date))}
				</StyledDate>
				<PercentBlock
					boys={report.boys.percentage}
					girls={report.girls.percentage}
					nonGender={report.nonGender.percentage}
					total={calcTotal(report)}
				/>
			</NavLink>

			<ButtonsBlock id={report.id} onRemove={onRemove} />
		</ItemContainer>
	)
}

export default ReportItem
