import React from 'react';
import { StyledSelectTree, styles } from './styled'

type TProps = {
	treeData: any,
	extraStyles?: any,
	value?: string,
	placeholder?: string,
	handleChange: (value: string) => void
}

export const TreeSelect = ({
	value = undefined,
	handleChange,
	placeholder = 'Please select',
	treeData,
	extraStyles
}: TProps): JSX.Element => {
	const onChange = (value: any) => {
		handleChange(value)
	}

	return (
		<StyledSelectTree

			dropdownStyle={styles.dropdownStyle}
			treeData={treeData}
			placeholder={placeholder}
			treeDefaultExpandAll
			onChange={onChange}
			value={value}
			style={extraStyles}
		/>
	)
}
