import { TreeSelect } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'src/constants';

export const styles = {
  dropdownStyle: {
    maxHeight: 400,
    overflow: 'auto'
  },
}

export const StyledSelectTree = styled(TreeSelect)`
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  background-color: ${COLORS.BACKGROUND_GREY};
  border: 1px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};
  height: 3rem;
  min-width: 10em;
  
  &::placeholder {
    color: ${COLORS.BLUE};
    text-align: center;
  }
  .ant-select-selector {
    border: none !important;
    background-color: ${COLORS.BACKGROUND_GREY} !important;
  }
  
  .ant-select-selection-placeholder {
    color: ${COLORS.BLUE};
    padding-right: 0 !important;
    margin-left: -8px !important;
    text-align: center;
  }
  
  .ant-select-arrow {
    color: ${COLORS.BLUE};
  }
  
  .ant-select-selector-focused, .ant-select-selection-search-input {
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
`
