import { IAdditionalDataItem } from '../redux/common/types';
import { toNumber } from 'lodash';


export const validateTotal = (previousTotal: number, newTotal: number, additionalData: IAdditionalDataItem[]) => {
  const additionalTotal =
    additionalData.reduce(
      (acc: any, current: IAdditionalDataItem) => {
        return acc + toNumber(current.count)
      }, 0)

  if (!previousTotal || (newTotal < previousTotal)){
    return newTotal === additionalTotal
  }

  return (newTotal - previousTotal) === additionalTotal
}

export const calcTotal = (report: any) => {
  return toNumber(report?.boys?.amount) + toNumber(report?.girls?.amount) + toNumber(report?.nonGender?.amount)
}

export const checkNonZeroVisitors = (report: any): boolean => {
  return !(
    !report.girls
    && !report.boys
    && !report.nonGender
  )
}
