import i18n from 'i18next'

export const GENDERS = [
  {
    label: i18n.t('common.boys'),
    value: 'M',
  },
  {
    label: i18n.t('common.girls'),
    value: 'F',
  },  {
    label: i18n.t('common.non_gender'),
    value: 'N',
  },
]
