import React from 'react';
import { AuthForm } from '../components';

const Home = () => {
	return (
		<>
			<AuthForm />
		</>
	)}

export default Home
