import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { StyledReactQuill } from './styled';

type TProps = {
	name?: string,
	value?: string,
	placeholder?: string,
	handleChange?: any,
}

const TextArea = ({
	name,
	value,
	handleChange,
	placeholder
}: TProps) => {
	const onChange = (value: string) => {
		handleChange(name, value)
	}

	return (
		<StyledReactQuill
			theme="snow"
			defaultValue={value}
			onChange={onChange}
			placeholder={placeholder}
		/>
	);
}

export default TextArea
