import React, {useRef, useState} from 'react';
import { DateRange, StyledStub } from './styled';

type TProps = {
	handleChange: (v: any) => void
	placeholder?: any
	value: any
}

const RangeDatePicker = ({ handleChange, placeholder, value,  ...props }: TProps): JSX.Element => {

	return (
		<DateRange
			{...props}
			placeholder={placeholder}
			onChange={handleChange}
			value={value}
			separator={''}
		/>
	)
}

export default RangeDatePicker
