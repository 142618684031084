import axios from 'axios'
import { baseApiUrl } from '../constants'
import { TGenerateOptions } from './types'
import storage from 'src/redux/store';

const instance = axios.create({
	baseURL: baseApiUrl,
	proxy: false
})

export const httpPost = (url: string, data: any, params: any) => sendRequest({
	method: 'POST', url, data, params,
})
export const httpGet = (url: string, params?: any) => sendRequest({ method: 'GET', url, params })
export const httpPatch = (url: string, data: any) => sendRequest({ method: 'PATCH', url, data })
export const httpDelete = (url: string, data: any) => sendRequest({ method: 'DELETE', url, data })
export const httpPut = (url: string, data: any) => sendRequest({ method: 'PUT', url, data })

const formatResponse = (response: any = {}) => ({
	data: response.data || {},
	status: response.status || 418,
	statusText: response.statusText || '',
	headers: response.headers || {},
})

const sendRequest = async ({
	method, url, data = undefined, params = undefined,
}: TGenerateOptions) => {
	const OPTIONS = await generateOptions({
		method, url, data, params,
	})

	try {
		const response = await instance(OPTIONS)
		return formatResponse(response)
	} catch (error) {
		throw formatResponse(error.response)
	}
}

const generateOptions = async ({
	method, url, data, params,
}: TGenerateOptions) => {
	const defaultHeaders = {
		'Content-Type': 'application/json',
	}

	const reduxToken = storage.store?.getState()?.auth.token ;
	const token = reduxToken || '';

	const authHeaders = {
		Authorization: `Bearer ${token}`,
	}

	return {
		method,
		url,
		data,
		params,
		headers: {
			...defaultHeaders,
			...(token ? authHeaders : {}),
		},
	}
}
