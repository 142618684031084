import React from 'react';
import { InfoText, FieldWrapper } from './styled';

interface Props {
	label?: string,
	children?: any,
}

const FieldWrap = ({
 children,
 label
}: Props): JSX.Element => {
	return (
		<FieldWrapper>
			{children}
			<InfoText>
				{ label }
			</InfoText>
		</FieldWrapper>
	)
}

export default FieldWrap
