import React from 'react';
import {
  FieldName,
  FieldValue,
  InfoTable,
  InfoRow,
  IndentTitle,
} from './styled';
import { renderPrice } from '../../../helpers/renderStrings';
import { IInitiativeReport } from '../../../redux/dailyReport';

type TProps = {
  report: any
};

const totalParticipants = (report: IInitiativeReport) => {
    return (report?.boys || 0) + (report?.girls || 0) + (report?.nonGender || 0);
}

const InitiativeReportInfo = ({ report }: TProps): JSX.Element => {
  return (
    <InfoTable>
      <InfoRow>
        <FieldName>Kategori:</FieldName>
        <FieldValue>{report?.type?.value}</FieldValue>
      </InfoRow>
      <div>
        <IndentTitle>Summering </IndentTitle>
      </div>
      <InfoRow>
        <FieldValue dangerouslySetInnerHTML={{ __html: report?.description || '' }} />
      </InfoRow>
      <InfoRow>
        <FieldName>Antal besökare: </FieldName>
        <FieldValue>{`${totalParticipants(report)} st`}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel killar: </FieldName>
        <FieldValue>{report?.boys}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel tjejer: </FieldName>
        <FieldValue>{report?.girls}</FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>Andel ickebinära: </FieldName>
        <FieldValue>{report?.nonGender}</FieldValue>
      </InfoRow>
    </InfoTable>
  );
};

export default InitiativeReportInfo;
