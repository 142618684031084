import { Formik } from 'formik';
import React, { FormEvent } from 'react';
import { ISocialMediaPost } from 'src/redux/dailyReport';
import { SOCIAL_POST_FIELDS } from '../../../../constants/forms';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'src/hooks';
import { removeSocialPost, updateSocialPost } from '../../../../redux/dailyReport/actions';
import TextInput from '../../../formControlls/TextInput';
import FieldWrap from 'src/components/FieldWrap';
import { TextArea } from 'src/components';
import { DeleteBlock, StyledForm, SectionText, TextAreaBlock } from './styled';

const { VIEWS, URL, LIKES, DESCRIPTION } = SOCIAL_POST_FIELDS

interface IProps {
  post: ISocialMediaPost
}

const initialValues = {
  [VIEWS]: '',
  [LIKES]: '',
  [URL]: '',
  [DESCRIPTION]: '',
}

export const SocialMediaPost = ({ post }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const removePost = () => {
    dispatch(removeSocialPost({ id: post.id }))
  }

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { value, name } = e.target as HTMLTextAreaElement
    dispatch(updateSocialPost({ id: post.id, value, name }))
  }

  const onFieldChange = (name: string, value: any) => {
    dispatch(updateSocialPost({ id: post.id, value, name }))
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={() => console.log('')}
  >
    {({ handleChange}) => (<>
        <DeleteBlock>
          <SectionText>Sociala medier inlägg</SectionText>
          <button className="delete" onClick={removePost}>
            <DeleteOutlined/>
            <span>Radera</span>
          </button>
        </DeleteBlock>
      <StyledForm onChange={onFormChange} noValidate>
        <FieldWrap>
          <TextInput
            type="text"
            name={URL}
            value={post[URL]}
            handleChange={handleChange}
            placeholder={t('dailyReport.link_label')}
          />
        </FieldWrap>
        <FieldWrap>
          <TextInput
            type="number"
            name={VIEWS}
            value={post[VIEWS]}
            handleChange={handleChange}
            placeholder={t('dailyReport.views')}
          />
        </FieldWrap>
        <FieldWrap>
          <TextInput
            type="number"
            name={LIKES}
            value={post[LIKES]}
            handleChange={handleChange}
            placeholder={t('dailyReport.likes')}
          />
        </FieldWrap>
        <TextAreaBlock>
          <TextArea
            name={DESCRIPTION}
            value={post[DESCRIPTION]}
            handleChange={onFieldChange}
            placeholder={t('dailyReport.description_placeholder')}
          />
        </TextAreaBlock>
      </StyledForm>
    </>)}
  </Formik>
}
