import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector, useRefreshCurrentReport } from 'src/hooks';
import { CreateReportHeader as Header, CreateFormTabs, CreateForm, BreadCrumbs, ErrorPopup } from '../components';
import { MainContainer } from './styled';
import { dailyReportReducerSelector } from '../redux/dailyReport';
import { commonReducerSelector } from '../redux/common';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { resetDailyReportData, setIsProcessed, updateDailyReport } from '../redux/dailyReport/actions';
import moment from 'moment';
import { DAILY_REPORT_FIELDS } from '../constants/forms';
import { useEditMode } from '../hooks/useEditMode';
import { createReportBreadcrumbs } from '../constants/navigation';

type TReportDetailParams = {
	id: string
}

const { DATE, CLUB } = DAILY_REPORT_FIELDS

const CreateReport = () => {
	const dispatch = useAppDispatch()
	const history = useHistory();
	const { isProcessed, error } = useAppSelector(dailyReportReducerSelector)
	const { currentClub } = useAppSelector(commonReducerSelector)

	const { id } = useParams<TReportDetailParams>()

	const { isEditMode, updateEditReportId } = useEditMode(id)

	useEffect(() => {
		updateEditReportId()
	}, [])

	useRefreshCurrentReport(id)

	useEffect(() => {
		!isEditMode && dispatch(updateDailyReport({
			isCounter: false,
			value: moment(),
			name: DATE,
			isEditMode,
			currentClub,
		}))

		return () => {
			// Clear report data on navigation change :
			dispatch(resetDailyReportData())
		}
	}, [])

	useEffect(() => {
		isProcessed && history.push(ROUTES.HOME)
		return () => {
			dispatch(setIsProcessed(false))
		}
	}, [isProcessed])

	return (
		<MainContainer>
			{ error ? <ErrorPopup error={error} /> : null}
			<BreadCrumbs data={createReportBreadcrumbs}/>
			<Header/>
			<CreateFormTabs/>
			<CreateForm/>
		</MainContainer>
	)}

export default CreateReport
