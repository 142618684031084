export const DAILY_REPORT_FIELDS = {
  DATE: 'date',
  VISITORS: 'total',
  GIRLS: 'girls',
  GIRLS_PERCENTS: 'girlsPercents',
  BOYS: 'boys',
  BOYS_PERCENTS: 'boysPercents',
  NON_GENDER: 'nonGender',
  NON_GENDER_PERCENTS: 'nonGenderPercents',
  CASHIER: 'amountSold',
  BALANCE: 'amountInCashier',
  SOCIAL_MEDIA_POSTS: 'socialMediaPosts',
  ONLINE_EVENTS: 'onlineEvents',
  INCIDENTS: 'incidents',
  INITIATIVES: 'initiatives',
  CLUB: 'clubId',
  NOTE: 'description',
}

export const EVENT_FORM_FIELDS = {
  EVENT_NAME: 'name',
  EVENT_VISITORS_COUNT: 'participants',
  EVENT_GIRLS_PERCENT: 'girls',
  EVENT_BOYS_PERCENT: 'boys',
  EVENT_NON_GENDER_PERCENT: 'nonGender',
  EVENT_NOTE: 'description',
}

export const SOCIAL_POST_FIELDS = {
  VIEWS: 'views',
  LIKES: 'likes',
  URL: 'url',
  DESCRIPTION: 'description',
}

export const ADDITIONAL_DATA_FIELDS = {
  GENDER: 'gender',
  AGE: 'age',
  COUNT: 'count',
}

export const INCIDENT_REPORT_FIELDS = {
  REPORTER: 'reporter',
  INCIDENT_TYPE: 'type',
  DESCRIPTION: 'description',
  ACTION: 'action',
}
export const LOGIN_FORM_FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
}

export const INITIATIVE_REPORT_FIELDS = {
  DATE: 'date',
  VISITORS: 'total',
  GIRLS: 'girls',
  GIRLS_PERCENTS: 'girlsPercents',
  BOYS: 'boys',
  BOYS_PERCENTS: 'boysPercents',
  TYPE: 'type',
  NON_GENDER: 'nonGender',
  NON_GENDER_PERCENTS: 'nonGenderPercents',
  CASHIER: 'cashier',
  SOCIAL_MEDIA_POSTS: 'socialMediaPosts',
  ONLINE_EVENTS: 'onlineEvents',
  INCIDENTS: 'incidents',
  INITIATIVES: 'initiatives',
  CLUB: 'club',
  BALANCE: 'balance',
  DESCRIPTION: 'description',
  PARTICIPANTS: 'participants',
}
