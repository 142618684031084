import React, { FormEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
import { dailyReportReducerSelector, IDailyReport } from 'src/redux/dailyReport';
import { commonReducerSelector } from 'src/redux/common';
import { DateField, FieldWrap, TextInput, TextArea, TreeSelect } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { DAILY_REPORT_FIELDS } from 'src/constants/forms';
import { updateDailyReport, updateCurrentReport, updateNewReport } from 'src/redux/dailyReport/actions';
import { getClubsSelectTree } from 'src/helpers/dataFormat';
import { TextAreaBlock } from './styled';
import { renderPercent } from '../../../../helpers/renderStrings';

const { DATE, VISITORS, CLUB, BOYS, BOYS_PERCENTS, GIRLS, GIRLS_PERCENTS, NON_GENDER, NON_GENDER_PERCENTS, CASHIER, BALANCE, NOTE } = DAILY_REPORT_FIELDS

export const DailyReportForm = ({ reportData }: { reportData: IDailyReport }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { editReportId, loading } = useAppSelector(dailyReportReducerSelector)
  const { clubsList } = useAppSelector(commonReducerSelector)
  const isEditMode = !!editReportId

  const onFieldChange = (name: string, value: any) => {
    dispatch(updateDailyReport({ isCounter: false, value, name, isEditMode }))
  }

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { value, name } = e.target as HTMLTextAreaElement
    const isCounter = name === BOYS || name === GIRLS || name === NON_GENDER
    const reducedValue = isCounter ? (parseInt(value, 10) || 0) : value
    dispatch(updateDailyReport({ isCounter, value: reducedValue, name, isEditMode }))
  }

  const clubsTree = useMemo(() => getClubsSelectTree(clubsList), [clubsList])

  useEffect(() => {
    dispatch(updateDailyReport({ isCounter: false, value: reportData[CLUB], name: CLUB, isEditMode }))
  }, [!!reportData[CLUB], !!clubsTree?.length])


  const onNoteChange = (name: string, value: string) => {
    const actionData = {
      isCounter: false,
      value,
      name,
    }
    if(isEditMode) {
      dispatch(updateCurrentReport(actionData))
    } else {
      dispatch(updateNewReport(actionData))
    }
  }

  if (loading) {
    return <Spin/>
  }

  return <Formik
    initialValues={reportData}
    onSubmit={(v) => console.log('values', v)}
  >
    {({ handleChange, handleSubmit }) => {
      return <form onSubmit={handleSubmit} noValidate onChange={onFormChange}>
        <FieldWrap>
          <DateField
            name={DATE}
            value={reportData[DATE] ? moment(reportData[DATE]) : moment()}
            handleChange={onFieldChange}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.club_placeholder')}>
          <TreeSelect
            treeData={clubsTree}
            name={CLUB}
            value={reportData[CLUB]}
            handleChange={onFieldChange}
            placeholder={t('dailyReport.club_placeholder')}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.local_visitors_info')}>
          <TextInput
            type="text"
            name={VISITORS}
            placeholder={t('dailyReport.digital_visitors_placeholder')}
            handleChange={handleChange}
            value={reportData[VISITORS]}
            disabled
          />
        </FieldWrap>
        {/*<FieldWrap label={t('dailyReport.digital_visitors_info')}>*/}
        {/*  <TextInput*/}
        {/*    type="text"*/}
        {/*    name={DIGITAL_VISITORS}*/}
        {/*    placeholder={t('dailyReport.digital_visitors_placeholder')}*/}
        {/*    handleChange={handleChange}*/}
        {/*    value={currentDailyReport[DIGITAL_VISITORS]}*/}
        {/*    disabled*/}
        {/*  />*/}
        {/*</FieldWrap>*/}
        <FieldWrap label={t('dailyReport.guys_info')}>
          <TextInput
            type="text"
            name={BOYS}
            placeholder={t('dailyReport.guys_placeholder')}
            handleChange={handleChange}
            value={reportData[BOYS]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.guys_percent_info')}>
          <TextInput
            type="text"
            name={BOYS_PERCENTS}
            placeholder={t('dailyReport.guys_percent_placeholder')}
            handleChange={handleChange}
            value={renderPercent(reportData[BOYS_PERCENTS]?.toFixed())}
            disabled
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.girls_info')}>
          <TextInput
            type="text"
            name={GIRLS}
            label={t('dailyReport.girls_info')}
            placeholder={t('dailyReport.girls_placeholder')}
            handleChange={handleChange}
            value={reportData[GIRLS]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.girls_percent_info')}>
          <TextInput
            type="text"
            name={GIRLS_PERCENTS}
            label={t('dailyReport.girls_percent_info')}
            placeholder={t('dailyReport.girls_percent_placeholder')}
            handleChange={handleChange}
            value={renderPercent(reportData[GIRLS_PERCENTS]?.toFixed())}
            disabled
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.non_gender_info')}>
          <TextInput
            type="text"
            name={NON_GENDER}
            placeholder={t('dailyReport.non_gender_placeholder')}
            handleChange={handleChange}
            value={reportData[NON_GENDER]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.non_gender_percent_info')}>
          <TextInput
            type="text"
            name={NON_GENDER_PERCENTS}
            placeholder={t('dailyReport.non_gender_percent_placeholder')}
            handleChange={handleChange}
            value={renderPercent(reportData[NON_GENDER_PERCENTS]?.toFixed())}
            disabled
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.sold_sek_info')}>
          <TextInput
            type="text"
            name={CASHIER}
            placeholder={t('dailyReport.sold_sek_placeholder')}
            handleChange={handleChange}
            value={reportData[CASHIER]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.missing_sek_info')}>
          <TextInput
            type="text"
            name={BALANCE}
            placeholder={t('dailyReport.missing_sek_placeholder')}
            handleChange={handleChange}
            value={reportData[BALANCE]}
          />
        </FieldWrap>
        <TextAreaBlock>
          <TextArea
            name={NOTE}
            handleChange={onNoteChange}
            value={reportData[NOTE]}
            placeholder={t('dailyReport.description')}
          />
        </TextAreaBlock>
      </form>
    }}
  </Formik>
}
