import styled from 'styled-components'
import { COLORS } from '../../../constants/colors';
import { Radio } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const RadioTabs = styled(Radio.Group)`
  display: flex;
  margin-top: 1rem;

  .ant-radio-button-wrapper-checked {
    background-color: ${COLORS.LIGHT_BLUE} !important;
    border: ${COLORS.LIGHT_BLUE} !important;
    color: ${COLORS.BLACK} !important;
    box-shadow: none !important;
  }
`

export const RadioTab= styled(Radio.Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 4rem !important;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: bold;

  .icon {
    margin-right: 0.4rem;
  }

  &:before {
    display: none !important;
  }

  &:first-child {
    border-radius: 0.5rem 0 0 0;
  }

  &:last-child {
    border-radius: 0 0.5rem  0 0;
  }
`

export const EditIcon = styled(EditOutlined)`
  color: ${COLORS.BLUE}
`
