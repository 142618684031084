import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './i18n/config'
import 'antd/dist/antd.css';
import { useAuth } from './hooks/useAuth';
import Routes from './routing';
import { AuthForm, Header } from './components';
import { ContentWrapper, AppWrapper } from './styled';

const App = () => {
	const { isAuth } = useAuth()

	return (
	<Router>
		<AppWrapper>
			<Header/>
			<ContentWrapper>
				{isAuth ? <Routes/> :  <AuthForm/>}
			</ContentWrapper>
		</AppWrapper>
	</Router>
)}

export default App
