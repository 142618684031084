import { Pagination } from 'antd'
import styled from 'styled-components'

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`

export const InfoText = styled.p`
  font-size: 1rem;
  font-weight: bold;
`

export const StyledPagination = styled(Pagination)`
`
