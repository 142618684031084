export const SET_ERROR = '[dailyReport] DAILY_SET_ERROR'
export const SET_LOADING = '[dailyReport] DAILY_SET_LOADING'
export const REMOVE_REPORT = '[dailyReport] REMOVE_REPORT'
export const SET_TAB = '[dailyReport] DAILY_SET_TAB'
export const ADD_POST = '[dailyReport] ADD_POST'
export const ADD_ONLINE_EVENT = '[dailyReport] ADD_ONLINE_EVENT'
export const REMOVE_POST = '[dailyReport] REMOVE_POST'
export const REMOVE_ONLINE_EVENT = '[dailyReport] REMOVE_ONLINE_EVENT'
export const UPDATE_CURRENT_REPORT_DATA = '[dailyReport] UPDATE_CURRENT_REPORT_DATA'
export const UPDATE_ONLINE_EVENT_DATA = '[dailyReport] UPDATE_ONLINE_EVENT_DATA'
export const UPDATE_POST_DATA = '[dailyReport] UPDATE_POST_DATA'
export const SEND_DAILY_REPORT = '[dailyReport] SEND_DAILY_REPORT'
export const UPDATE_REPORT_PERCENTS_DATA = '[dailyReport] UPDATE_REPORT_PERCENTS_DATA'
export const UPDATE_DAILY_REPORT = '[dailyReport] UPDATE_DAILY_REPORT'
export const UPDATE_MULTIPLE_REPORT_FIELDS = '[dailyReport] UPDATE_MULTIPLE_REPORT_FIELDS'
export const UPDATE_MULTIPLE_CURRENT_REPORT_FIELDS = '[dailyReport] UPDATE_MULTIPLE_CURRENT_REPORT_FIELDS'
export const REMOVE_ADDITIONAL_ITEM = '[dailyReport] REMOVE_ADDITIONAL_ITEM'
export const ADD_ADDITIONAL_ITEM = '[dailyReport] ADD_ADDITIONAL_ITEM'
export const SET_ADDITIONAL_ITEM = '[dailyReport] SET_ADDITIONAL_ITEM'
export const UPDATE_ADDITIONAL_ITEM = '[dailyReport] UPDATE_ADDITIONAL_ITEM'
export const UPDATE_NEW_REPORT_DATA = '[dailyReport] UPDATE_NEW_REPORT_DATA'
export const GET_CURRENT_REPORT = '[dailyReport] GET_CURRENT_REPORT'
export const SET_CURRENT_REPORT = '[dailyReport] SET_CURRENT_REPORT'
export const GET_DEFAULT_REPORT_VALUES = '[dailyReport] GET_DEFAULT_REPORT_VALUES'
export const SET_PREFILLED_DATA = '[dailyReport] SET_PREFILLED_DATA'
export const SET_IS_AMOUNT_VALID = '[dailyReport] SET_IS_AMOUNT_VALID'
export const SET_IS_PROCESSED = '[dailyReport] SET_IS_PROCESSED'
export const EDIT_DAILY_REPORT = '[dailyReport] EDIT_DAILY_REPORT'
export const RESET_DAILY_REPORT_DATA = '[dailyReport] RESET_DAILY_REPORT_DATA'
export const SET_EDIT_REPORT_ID = '[dailyReport] SET_EDIT_REPORT_ID'

export const setLoading = (data: boolean) => ({ type: SET_LOADING, data })
export const setError = (data: string | null) => ({ type: SET_ERROR, data })
export const setTab = (data: string) => ({ type: SET_TAB, data })
export const removeReport = (data: string) => ({ type: REMOVE_REPORT, data })
export const addSocialPost = (data: { id: string }) => ({ type: ADD_POST, data })
export const addOnlineEvent = (data: { id: string }) => ({ type: ADD_ONLINE_EVENT, data })
export const removeSocialPost = (data: { id: string }) => ({ type: REMOVE_POST, data })
export const removeOnlineEvent = (data: { id: string }) => ({ type: REMOVE_ONLINE_EVENT, data })
export const updateOnlineEvent = (data: any) => ({ data, type: UPDATE_ONLINE_EVENT_DATA })
export const updateSocialPost = (data: any) => ({ data, type: UPDATE_POST_DATA })
export const updateMultipleReportFields = (data: any) => ({ data, type: UPDATE_MULTIPLE_REPORT_FIELDS })
export const updateMultipleCurrentReportFields = (data: any) => ({ data, type: UPDATE_MULTIPLE_CURRENT_REPORT_FIELDS })
export const updateDailyReport = (data: any) => ({ data, type: UPDATE_DAILY_REPORT })
export const sendDailyReport = (data: { isEditMode: boolean }) => ({ data, type: SEND_DAILY_REPORT })
export const addAdditionalItem = (data: any) => ({ data, type: ADD_ADDITIONAL_ITEM })
export const setAdditionalItem = (data: any) => ({ data, type: SET_ADDITIONAL_ITEM })
export const updateAdditionalItem = (data: any) => ({ data, type: UPDATE_ADDITIONAL_ITEM })
export const removeAdditionalItem = (data: any) => ({ data, type: REMOVE_ADDITIONAL_ITEM })
export const updateNewReport = (data: any) => ({ data, type: UPDATE_NEW_REPORT_DATA })
export const updateCurrentReport = (data: any) => ({ data, type: UPDATE_CURRENT_REPORT_DATA })
export const getCurrentReport = (data: string) => ({ data, type: GET_CURRENT_REPORT })
export const setCurrentReport = (data: any) => ({ data, type: SET_CURRENT_REPORT })
export const setPrefilledData = (data: any) => ({ data, type: SET_PREFILLED_DATA })
export const setIsAmountValid = (data: boolean) => ({ type: SET_IS_AMOUNT_VALID, data })
export const setEditReportId = (data: string | null) => ({ type: SET_EDIT_REPORT_ID, data })
export const setIsProcessed = (data: boolean) => ({ type: SET_IS_PROCESSED, data })
export const getDefaultReportValues = (data: { date: any; clubId: string | null }) => ({ data, type: GET_DEFAULT_REPORT_VALUES })
export const editDailyReport = (data: any) => ({ data, type: EDIT_DAILY_REPORT })
export const resetDailyReportData = () => ({ type: RESET_DAILY_REPORT_DATA })
