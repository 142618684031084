import React from 'react';
import { StyledSelectTree, styles } from './styled'

type TProps = {
	treeData: any,
	name: string,
	value?: string,
	placeholder?: string,
	handleChange: any
}

export const TreeSelect = ({
	value,
	handleChange,
	name,
	placeholder = 'Please select',
	treeData
}: TProps): JSX.Element => {
	const onChange = (value: any) => {
		handleChange(name, value)
	}

	return (
		<StyledSelectTree
			dropdownStyle={styles.dropdownStyle}
			treeData={treeData}
			placeholder={placeholder}
			treeDefaultExpandAll
			onChange={onChange}
			defaultValue={value}
		/>
	)
}
