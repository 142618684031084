import React, { useMemo } from 'react';
import { StyledSelect, StyledOption } from './styled'

type TProps = {
	list: unknown[] | null,
	name: string,
	value?: string,
	placeholder?: string,
	isValid?: boolean,
	handleChange: (name: string, value: string) => void
}

export const Select = ({
	list,
	value,
	handleChange,
	name,
	placeholder,
	isValid = true
}: TProps): JSX.Element => {
	const onChange = (value: any) => {
		handleChange(name, value)
	}

	const mapItems = (item: any) => {
		return <StyledOption key={item?.label} value={item?.value}>
			<span>{item.label}</span>
		</StyledOption>
	}

	const mappedItems = useMemo(() => list?.map(mapItems), [list])

	return (
		<StyledSelect placeholder={placeholder} onChange={onChange} value={value} isValid={isValid}>
			{mappedItems}
		</StyledSelect>
	)
}
