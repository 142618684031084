export enum COLORS  {
  WHITE = '#ffffff',
  LIGHT_BLUE = '#E8ECFE',
  BLUE = '#4268F6',
  BACKGROUND_GREY = '#F6F7FB',
  MAIN_TEXT = '#1C1C38',
  RED = '#FF3168',
  ORANGE = '#FB8832',
  FONT_GREY = '#AEB3B7',
  BLACK = '#000000',
  PURPLE = '#9B51E0',
  GREY = '#E6E5E6',
  GREEN = '#49CC86',
  GREEN_LIGHT = '#31FAB9',
  GREEN_LIGHTER = '#E7FEF6',
}
