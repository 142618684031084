import React, { useEffect } from 'react';
import { NotificationWrap, NotificationText } from './styled';
import { useTranslation } from 'react-i18next';
import { CrossSVG, CheckmarkSVG } from 'src/assets/svg';
import { setNotification } from '../../../redux/common/actions';
import { useDispatch } from 'react-redux';

export const Notification = ({ id }: { id: string | null }): JSX.Element | null => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	if (!id) return null
	const onClose = () => dispatch(setNotification(null))

	useEffect(() => {
		return () => {
			dispatch(setNotification(null))
		}
	}, [])

	return (
		<NotificationWrap>
			<CheckmarkSVG/>
			<NotificationText>
				{t(id)}
			</NotificationText>
			<CrossSVG onClick={onClose}/>
		</NotificationWrap>
	)
}
