import {IUpdatePayload} from "./types";

export const SET_ERROR = '[initiativeReport] DAILY_SET_ERROR'
export const SET_LOADING = '[initiativeReport] DAILY_SET_LOADING'
export const ADD_INITIATIVE_REPORT = '[initiativeReport] ADD_INITIATIVE_REPORT'
export const SET_INITIATIVE_REPORTS = '[initiativeReport] SET_INITIATIVE_REPORTS'
export const REMOVE_INITIATIVE_REPORT = '[initiativeReport] REMOVE_INITIATIVE_REPORT'
export const UPDATE_INITIATIVE_REPORT_DATA = '[initiativeReport] UPDATE_INITIATIVE_REPORT_DATA'
export const UPDATE_INITIATIVE_REPORT = '[initiativeReport] UPDATE_INITIATIVE_REPORT'
export const GET_STAFF_LIST = '[initiativeReport] GET_STAFF_LIST'
export const GET_INITIATIVE_TYPES_LIST = '[initiativeReport] GET_INITIATIVE_TYPES_LIST'
export const SET_STAFF_LIST = '[initiativeReport] SET_STAFF_LIST'
export const SET_INITIATIVE_TYPES_LIST = '[initiativeReport] SET_INITIATIVE_TYPES_LIST'
export const UPDATE_MULTIPLE_REPORT_FIELDS = '[initiativeReport] UPDATE_MULTIPLE_REPORT_FIELDS'
export const UPDATE_MULTIPLE_INITIATIVE_REPORT_FIELDS = '[initiativeReport] UPDATE_MULTIPLE_INITIATIVE_REPORT_FIELDS'
export const RESET_INITIATIVE_REPORT_DATA = '[initiativeReport] RESET_INITIATIVE_REPORT_DATA'

export const setLoading = (data: boolean) => ({ type: SET_LOADING, data })
export const setError = (data: null | string) => ({ type: SET_ERROR, data })
export const getStaffList = () => ({ type: GET_STAFF_LIST })
export const getInitiativeTypesList = (data: string) => ({ data, type: GET_INITIATIVE_TYPES_LIST })
export const setInitiativeTypesList = (data: any) => ({ type: SET_INITIATIVE_TYPES_LIST, data })
export const setInitiativeReports = (data: any) => ({ type: SET_INITIATIVE_REPORTS, data })
export const addInitiativeReport = (data: { id: string }) => ({ type: ADD_INITIATIVE_REPORT, data })
export const removeInitiativeReport = (data: { id: string }) => ({ type: REMOVE_INITIATIVE_REPORT, data })
export const updateInitiativeReport = (data: IUpdatePayload) => ({ type: UPDATE_INITIATIVE_REPORT, data })
export const updateInitiativeReportData = (data: IUpdatePayload) =>
  ({ type: UPDATE_INITIATIVE_REPORT_DATA, data })
export const updateMultipleReportFields = (data: any) => ({ data, type: UPDATE_MULTIPLE_REPORT_FIELDS })
export const updateMultipleInitiativeReportFields = (data: any) => ({ data, type: UPDATE_MULTIPLE_INITIATIVE_REPORT_FIELDS })
export const resetInitiativeReportData = () => ({ type: RESET_INITIATIVE_REPORT_DATA })