import { toNumber, isNaN } from 'lodash'

export const renderPercent = (percent: number | string | undefined) => {
  if (!percent || isNaN(percent) || percent === 'NaN' || percent === 0){
    return '0%'
  }
  if (typeof percent === 'number') {
    return `${percent.toFixed(2)}%`
  } else {
    return `${toNumber(percent).toFixed(2)}%`
  }
}

export const renderAge = (age: number | string | undefined) => {
  if (!age){
    return ''
  }
  if (typeof age === 'number') {
    return `${age.toFixed()}år`
  } else {
    return `${toNumber(age).toFixed()}år`
  }
}


export const renderPrice = (price: number | string | undefined) => {
  if (!price){
    return ''
  }
  if (typeof price === 'number') {
    return `${price.toFixed()}`
  } else {
    return `${toNumber(price).toFixed()}`
  }
}
