import React, { useState, useEffect } from 'react';
import { COLORS } from 'src/constants/colors';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { ButtonMain } from 'src/components/common/Button/styled';
import {
  HeaderWrap,
  ButtonsWrap,
  Title,
  InfoText,
  TitleWrap,
  InfoDate,
} from './styled';
import { commonReducerSelector } from '../../../redux/common';
import { deleteReport, setIsReportDeleted } from '../../../redux/common/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { Modal } from 'src/components';
import {
  dailyReportReducerSelector,
  IDailyReport,
} from '../../../redux/dailyReport';
import { BucketSVG, BackBlueSVG, EditSVG, FileSVG } from 'src/assets/svg';
import { DailyReportApi } from 'src/api';
import { Spin } from 'antd';

type TProps = {
  report: IDailyReport | null;
};

const ReportHeader = ({ report }: TProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)
  const { loading } = useAppSelector(dailyReportReducerSelector);
  const { isReportDeleted } = useAppSelector(commonReducerSelector)
  
  const onPdfDownload = () => {
    setPdfLoading(true)
    DailyReportApi.downloadPdf({
      clubId: report?.clubId,
      date: report?.date,
    }).then(({ data }) => {
      setPdfLoading(false)
      window.open(data, '_blank');
      //window.location.href = response.url;
    });
  };

  useEffect(() => {
		isReportDeleted && history.push(ROUTES.HOME)
		return () => {
			dispatch(setIsReportDeleted(false))
		}
	}, [isReportDeleted])

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const hideModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);
  const onRemove = () => dispatch(deleteReport(report?.id));

  return (
    <HeaderWrap>
      <TitleWrap>
        <Title>Granska dagsrapport</Title>
        <InfoText>
          Granska följande dagsrapport
          <InfoDate>{report?.date}</InfoDate>
        </InfoText>
      </TitleWrap>
      <ButtonsWrap>
        <ButtonMain bgColor={COLORS.WHITE} color={COLORS.BLUE}>
          <NavLink to={ROUTES.HOME}>
            <BackBlueSVG />
            Tillbaka
          </NavLink>
        </ButtonMain>
        <ButtonMain
          bgColor={COLORS.BLUE}
          color={COLORS.WHITE}
          onClick={onPdfDownload}
        >
          {pdfLoading  ? <Spin size={'small'}/>  :<FileSVG/>}
          Ladda ner PDF
          {/*<Link to={link} target="_blank" download>Download</Link>*/}
        </ButtonMain>
        <ButtonMain bgColor={COLORS.ORANGE} color={COLORS.WHITE}>
          <NavLink to={`${ROUTES.EDIT}/${report?.id}`}>
            <EditSVG />
            Redigera
          </NavLink>
        </ButtonMain>
        <ButtonMain
          bgColor={COLORS.RED}
          color={COLORS.WHITE}
          onClick={showModal}
        >
          <BucketSVG />
          Radera
        </ButtonMain>
      </ButtonsWrap>
      <Modal
        isVisible={isModalVisible}
        onCancel={hideModal}
        onConfirm={onRemove}
        loading={loading}
        title={'Are you sure you need to remove this report?'}
      />
    </HeaderWrap>
  );
};

export default ReportHeader;
