import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledHeaderTitle, HeaderWrap, StyledHeader, ProfileButton } from './styled';
import bullets from '../../assets/svg/bullets.svg'
import { StyledPopover } from './styled'
import LogoutButton from "../LogoutButton";
import { useAuth } from 'src/hooks/useAuth';
import { TAuthState } from 'src/redux/auth/types';
import { ROUTES } from 'src/constants';

function getUserName(authData: TAuthState): string{
	const { profileData } = authData;
	if (!profileData) {
		return '';
	} 
	return `${profileData.firstName} ${profileData.lastName}`;
}

const Header = (): JSX.Element => {
	const { isAuth, authData } = useAuth()
	
	return (
		<HeaderWrap>
			<StyledHeader>
				<StyledHeaderTitle>
					<NavLink to={ROUTES.HOME}>
						<div>Ungdomsappen</div>
					</NavLink>
				</StyledHeaderTitle>
				{isAuth && <ProfileButton>
					<StyledPopover content={LogoutButton}>
						<span>
							{ getUserName(authData) }
						</span>
						<img src={bullets}/>
					</StyledPopover>
				</ProfileButton>}
			</StyledHeader>
		</HeaderWrap>
	)
}

export default Header
