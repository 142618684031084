import { TAction } from '@types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  SEND_DAILY_REPORT,
  UPDATE_DAILY_REPORT,
  GET_CURRENT_REPORT,
  GET_DEFAULT_REPORT_VALUES,
  setError,
  setLoading,
  updateNewReport,
  updateMultipleReportFields,
  setCurrentReport,
  setPrefilledData,
  setIsProcessed,
  updateCurrentReport,
  updateMultipleCurrentReportFields,
  resetDailyReportData,
} from './actions';
import { resetIncidentReportData } from '../incidentReport/actions';
import { resetInitiativeReportData } from '../initiativeReport/actions';
import {
  dailyReportReducerSelector,
  newDailyReportSelector,
  currentDailyReportSelector, initiativeReportReducerSelector
} from './selectors';
import { getPercents } from '../../helpers/dataFormat';
import { DAILY_REPORT_FIELDS } from '../../constants/forms';
import { DailyReportApi } from '../../api';
import { IAdditionalDataItem, IIncidentReport, IInitiativeReport } from './types';
import { toNumber } from 'lodash';
import { formatDate } from 'src/services';
import { incidentReportReducerSelector } from '../incidentReport';
import moment from 'moment';
import { getReportsList, getReportsStatistic, setCurrentClub, setNotification } from '../common/actions';
import { dateCommon } from 'src/constants';
import { commonReducerSelector } from '../common';
import { checkNonZeroVisitors } from '../../helpers/counters';

const { BOYS_PERCENTS, GIRLS_PERCENTS, NON_GENDER_PERCENTS, VISITORS, NON_GENDER, BOYS, GIRLS } = DAILY_REPORT_FIELDS

export function* watchDailyReport() {
  yield takeLatest(SEND_DAILY_REPORT, sendDailyReportAsync)
  yield takeLatest(UPDATE_DAILY_REPORT, updateDailyReportSaga)
  yield takeLatest(GET_CURRENT_REPORT, getCurrentReportSaga)
  yield takeLatest(GET_DEFAULT_REPORT_VALUES, getDefaultReportValuesSaga)
}

export function* sendDailyReportAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  const { isEditMode } = action.data
  const reportSelector = isEditMode
    ? currentDailyReportSelector
    : newDailyReportSelector

  try {

    const {
      amountSold,
      amountInCashier,
      boys,
      girls,
      nonGender,
      boysPercents,
      girlsPercents,
      nonGenderPercents,
      ...rest
    } = yield select(reportSelector)

    const { additionalDataItems } = yield select(dailyReportReducerSelector)

    const {
      incidentReports
    } = yield select(incidentReportReducerSelector)

    const {
      initiativeReports
    } = yield select(initiativeReportReducerSelector)

    const incidents = incidentReports?.filter((report: IIncidentReport) => !!report.reporter)

    const reducedAdditionalData = additionalDataItems?.reduce((acc: Array<{ age: number, gender: string }>, item: IAdditionalDataItem) => {
      for (let i = 0; i < toNumber(item?.count); i++) {
        acc.push({
          age: item.age,
          gender: item.gender
        })
      }
      return acc
    }, [])

    const reportData = {
      ...rest,
      boys: {
        amount: toNumber(boys)?.toString(),
        percentage: toNumber(boysPercents)?.toString()
      },
      girls: {
        amount: toNumber(girls)?.toString(),
        percentage: toNumber(girlsPercents)?.toString()
      },
      nonGender: {
        amount: toNumber(nonGender)?.toString(),
        percentage: toNumber(nonGenderPercents)?.toString()
      },
      cashier: {
        amountSold: toNumber(amountSold || 0),
        amountInCashier: toNumber(amountInCashier || 0),
      },
      incidents,
      initiatives: initiativeReports?.filter(checkNonZeroVisitors).map((report: IInitiativeReport) => ({
        ...report,
        cashier: {
          amountSold: toNumber(report?.cashier || 0),
          amountInCashier: toNumber(report?.balance || 0),
        },
        type: report.type ? report.type : { id: report.typeId }
      })) || [],
      addedUsers: reducedAdditionalData
    }

    if (isEditMode) {
      yield call(DailyReportApi.editReport, reportData)
    } else {
      yield call(DailyReportApi.sendDailyReport, reportData)
    }

    yield put(setIsProcessed(true))
    yield put(setNotification(`dailyReport.${isEditMode ? 'updated_success' : 'created_success'}`))
    yield put(setCurrentClub(reportData.clubId))
    const { datesRange } = yield select(commonReducerSelector)

    const reportsDTO = {
      page: 1,
      dateFrom: formatDate(datesRange?.[0], dateCommon),
      dateTo: formatDate(datesRange?.[1], dateCommon),
      clubId: reportData.clubId
    }

    yield put(getReportsList(reportsDTO))
    yield put(getReportsStatistic(reportsDTO))
    yield put(resetDailyReportData())
    yield put(resetIncidentReportData())
    yield put(resetInitiativeReportData())

  } catch (err) {
    yield put(setError(err))
  } finally {
    yield put(setLoading(false))
  }
}

export function* updateDailyReportSaga(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { isCounter, name, isEditMode, currentClub, value } = action.data
    if (isEditMode) {
      yield put(updateCurrentReport({ isCounter, name, value }))
    } else {
      yield put(updateNewReport({ isCounter, name, currentClub, value }))
    }

    if (isCounter) {
      const currentReport = yield select(currentDailyReportSelector)
      const newReport = yield select(newDailyReportSelector)
      const targetReport = isEditMode ? currentReport : newReport
      const percentFields = {
        [GIRLS_PERCENTS]: getPercents(targetReport[VISITORS], targetReport[GIRLS]),
        [BOYS_PERCENTS]: getPercents(targetReport[VISITORS], targetReport[BOYS]),
        [NON_GENDER_PERCENTS]: getPercents(targetReport[VISITORS], targetReport[NON_GENDER]),
      }

      if (isEditMode) {
        yield put(updateMultipleCurrentReportFields(percentFields))
      } else {
        yield put(updateMultipleReportFields(percentFields))
      }
    }
  } catch (err) {
    yield put(setError(err))
    console.log('updateDailyReportCounters ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* getCurrentReportSaga(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { data } = yield call(DailyReportApi.getReport, action.data)
    const total = toNumber(data.boys?.amount) + toNumber(data.girls?.amount) + toNumber(data.nonGender?.amount)
    const formattedReport = {
      ...data,
      total,
      boys: toNumber(data.boys?.amount),
      boysPercents: toNumber(data.boys?.percentage),
      girls: toNumber(data.girls?.amount),
      girlsPercents: toNumber(data.girls?.percentage),
      nonGender: toNumber(data.nonGender?.amount),
      nonGenderPercents: toNumber(data.nonGender?.percentage),
      amountInCashier: data.cashier.amountInCashier,
      amountSold: data.cashier.amountSold,
    }

    yield put(setPrefilledData({
      girls: toNumber(data.girls?.amount),
      boys: toNumber(data.boys?.amount),
      nonGender: toNumber(data.nonGender?.amount),
      total
    }))

    yield put(setCurrentReport(formattedReport))

  } catch (err) {
    yield put(setError(err))
    console.log('getCurrentReportSaga ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* getDefaultReportValuesSaga(action: TAction) {
  const { clubId, date } = action.data
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { data } = yield call(DailyReportApi.getDefaultReportData, { clubId, date: formatDate(moment(date)) })
    const percentFields = {
      [GIRLS_PERCENTS]: getPercents(data[VISITORS], data[GIRLS]),
      [BOYS_PERCENTS]: getPercents(data[VISITORS], data[BOYS]),
      [NON_GENDER_PERCENTS]: getPercents(data[VISITORS], data[NON_GENDER]),
    }
    yield put(updateMultipleReportFields({ ...data, ...percentFields }))
    yield put(setPrefilledData({ ...data }))

  } catch (err) {
    yield put(setError(err))
    console.log('getDefaultReportValuesSaga ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}
