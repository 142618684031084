import { v4 as uuid } from 'uuid';
import {
  ADD_ONLINE_EVENT,
  ADD_POST,
  REMOVE_POST,
  REMOVE_ONLINE_EVENT,
  SET_ERROR,
  SET_LOADING,
  SET_TAB,
  UPDATE_ONLINE_EVENT_DATA,
  UPDATE_POST_DATA,
  UPDATE_MULTIPLE_REPORT_FIELDS,
  REMOVE_ADDITIONAL_ITEM,
  UPDATE_ADDITIONAL_ITEM,
  ADD_ADDITIONAL_ITEM,
  UPDATE_NEW_REPORT_DATA,
  SET_CURRENT_REPORT,
  SET_PREFILLED_DATA,
  SET_IS_AMOUNT_VALID,
  SET_IS_PROCESSED,
  UPDATE_CURRENT_REPORT_DATA,
  UPDATE_MULTIPLE_CURRENT_REPORT_FIELDS, RESET_DAILY_REPORT_DATA, SET_EDIT_REPORT_ID, SET_ADDITIONAL_ITEM
} from './actions'
import { TDailyReportReducer } from './types';
import { TAction } from '@types';
import { TABS } from 'src/constants/reports';
import { DAILY_REPORT_FIELDS } from '../../constants/forms';

const { DATE, VISITORS, BOYS, BOYS_PERCENTS, GIRLS_PERCENTS, NON_GENDER_PERCENTS, GIRLS, CLUB, NON_GENDER, CASHIER, BALANCE, NOTE } = DAILY_REPORT_FIELDS

export const initialOnlineEvent = {
  id: uuid(),
  name: '',
  description: '',
  participants: null,
  boys: null,
  girls: null,
  nonGender: null,
  report: null
}

export const initialSocialPost = {
  id: uuid(),
  url: '',
  description: '',
  likes: null,
  views: null,
  report: null,
}

export const initialAdditionalDataItems = {
  id: uuid(),
  count: null,
  gender: null,
}

export const initialNewReport = {
  [DATE]: '',
  [VISITORS]: 0,
  [BOYS]: 0,
  [BOYS_PERCENTS]: 0,
  [GIRLS]: 0,
  [GIRLS_PERCENTS]: 0,
  [NON_GENDER]: 0,
  [NON_GENDER_PERCENTS]: 0,
  [CASHIER]: '',
  [BALANCE]: '',
  [CLUB]: '',
  [NOTE]: '',
  socialMediaPosts: [],
  onlineEvents: []
}

export const initialState = {
  loading: false,
  isEditMode: false,
  activeTab: TABS.FIRST,
  error: null,
  clubsList: [],
  socialPostsList: [],
  onlineEventsList: [],
  additionalDataItems: [],
  isAmountValid: true,
  currentDailyReport: initialNewReport,
  prefilledData: null,
  newReport: initialNewReport,
  isProcessed: false,
  currentPage: 1,
  editReportId: null,
}

export const dailyReportReducer = (state: TDailyReportReducer = initialState, action: TAction) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.data }
    case SET_TAB:
      return {
        ...state,
        activeTab: action.data,
      }
    case SET_EDIT_REPORT_ID:
      return {
        ...state,
        editReportId: action.data,
      }
    case ADD_POST: {
      return state.editReportId ? {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          socialMediaPosts: [...state.currentDailyReport.socialMediaPosts, action.data],
        }
      } : {
        ...state,
        newReport: {
          ...state.newReport,
          socialMediaPosts: [...state.newReport.socialMediaPosts, action.data],
        }
      }
    }
    case ADD_ONLINE_EVENT: {
      return state.editReportId ? {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          onlineEvents: [...state.currentDailyReport.onlineEvents, action.data],
        }
      } : {
        ...state,
        newReport: {
          ...state.newReport,
          onlineEvents: [...state.newReport.onlineEvents, action.data],
        }
      }
    }
    case ADD_ADDITIONAL_ITEM:
      return {
        ...state,
        additionalDataItems: [...state.additionalDataItems, action.data],
      }
    case SET_ADDITIONAL_ITEM:
      return {
        ...state,
        additionalDataItems: action.data,
      }
    case REMOVE_POST: {
      const updatedPosts = state.editReportId
        ? state.currentDailyReport.socialMediaPosts.filter((post: any) => post.id !== action.data.id)
        : state.newReport.socialMediaPosts.filter((post: any) => post.id !== action.data.id)

      return state.editReportId ? {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          socialMediaPosts: updatedPosts,
        }
      } : {
        ...state,
        newReport: {
          ...state.newReport,
          socialMediaPosts: updatedPosts,
        }
      }
    }
    case REMOVE_ONLINE_EVENT: {
      const updatedPosts = state.editReportId
        ? state.currentDailyReport.onlineEvents.filter((post: any) => post.id !== action.data.id)
        : state.newReport.onlineEvents.filter((post: any) => post.id !== action.data.id)

      return state.editReportId ? {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          onlineEvents: updatedPosts,
        }
      } : {
        ...state,
        newReport: {
          ...state.newReport,
          onlineEvents: updatedPosts,
        }
      }
    }
    case REMOVE_ADDITIONAL_ITEM: {
      const updatedItems = state.additionalDataItems?.filter((item) => {
        return item.id !== action.data.id
      })
      return {
        ...state,
        additionalDataItems: updatedItems,
      }
    }
    case UPDATE_ONLINE_EVENT_DATA: {
      const updatedEvents = state?.editReportId ?
        state.currentDailyReport.onlineEvents.map((event: any) => {
          const { id, name, value } = action.data
          return event.id !== id
            ? event
            : { ...event, [name]: value }
        })
        : state.newReport.onlineEvents.map((event: any) => {
          const { id, name, value } = action.data
          return event.id !== id
            ? event
            : { ...event, [name]: value }
        })
      return state?.editReportId ? {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          onlineEvents: updatedEvents
        },
      } : {
        ...state,
          newReport: {
            ...state.newReport,
            onlineEvents: updatedEvents
          },
      }
    }
    case UPDATE_POST_DATA: {
      const updatedEvents = state?.editReportId ?
        state.currentDailyReport.socialMediaPosts.map((event: any) => {
          const { id, name, value } = action.data
          return event.id !== id
            ? event
            : { ...event, [name]: value }
        })
        : state.newReport.socialMediaPosts.map((event: any) => {
          const { id, name, value } = action.data
          return event.id !== id
            ? event
            : { ...event, [name]: value }
        })
      return state?.editReportId ? {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          socialMediaPosts: updatedEvents
        },
      } : {
        ...state,
        newReport: {
          ...state.newReport,
          socialMediaPosts: updatedEvents
        },
      }
    }
    case UPDATE_ADDITIONAL_ITEM: {
      const updatedItems = state.additionalDataItems?.map((item) => {
        const { id, name, value } = action.data
        return item.id !== id
          ? item
          : { ...item, [name]: value }
      })
      return {
        ...state,
        additionalDataItems: updatedItems,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.data,
      }
    }
    case SET_PREFILLED_DATA: {
      return {
        ...state,
        prefilledData: action.data,
      }
    }
    case UPDATE_NEW_REPORT_DATA: {
      const { value, name, isCounter } = action.data
			const updatedReport = isCounter
        ? Object.assign({ ...state.newReport }, {
          [name]: (value),
          [VISITORS]: state?.newReport[VISITORS] - state?.newReport[name] + +value
        })
        : Object.assign({ ...state.newReport }, {
					[name]: value,
				})
			return {
        ...state,
        newReport: updatedReport
      }
    }
    case UPDATE_MULTIPLE_REPORT_FIELDS:
      return {
        ...state,
        newReport: {
          ...state.newReport,
          ...action.data
        }
      }
    case UPDATE_MULTIPLE_CURRENT_REPORT_FIELDS:
      return {
        ...state,
        currentDailyReport: {
          ...state.currentDailyReport,
          ...action.data
        }
      }
    case UPDATE_CURRENT_REPORT_DATA: {
      const { value, name, isCounter } = action.data
      const updatedReport = isCounter
        ? Object.assign({ ...state.currentDailyReport }, {
          [name]: value,
          [VISITORS]: (state.currentDailyReport[VISITORS] - state?.currentDailyReport[name]) + +value
        })
        : Object.assign({ ...state.currentDailyReport }, {
          [name]: value,
        })
      return {
        ...state,
        currentDailyReport: updatedReport
      }
    }
    case SET_CURRENT_REPORT:
      return {
        ...state,
        currentDailyReport: action.data
      }
    case SET_IS_PROCESSED:
      return {
        ...state,
        isProcessed: action.data
      }
    case RESET_DAILY_REPORT_DATA:
      return {
        ...state,
        socialPostsList: [],
        onlineEventsList: [],
        additionalDataItems: [],
        isAmountValid: true,
        currentDailyReport: initialNewReport,
        prefilledData: null,
        newReport: initialNewReport,
        isProcessed: false,
      }
    case SET_IS_AMOUNT_VALID:
      return !state.additionalDataItems.length ? {
        ...state,
        isAmountValid: action.data,
        additionalDataItems: [initialAdditionalDataItems]
      } : {
        ...state,
        isAmountValid: action.data
      }
    default:
      return state
  }
}
