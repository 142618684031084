import { httpGet, httpDelete } from '../services'

const CommonApi = {
	getClubsList() {
		return httpGet('/analytics/clubs')
	},
	getReportsList(data) {
		return httpGet('/analytics/dailyReport', data)
	},
	getReportsStatistic(data) {
		return httpGet(`/analytics/statistics${data.clubId? '/' + data.clubId : ''}`, data)
	},
	getReportPdf(data) {
		return httpGet(`/analytics/${data.clubId}/pdf`, data)
	},
	removeReport(id) {
		return httpDelete(`/analytics/dailyReport/${id}`)
	}
}

export default CommonApi
