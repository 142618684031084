import { takeLatest, put, call } from 'redux-saga/effects'
import {
  setLoading,
  setToken,
  SIGN_IN,
  LOG_OUT,
} from './actions'
import {setCurrentClub, setError} from '../common/actions'
import { setProfileData } from '../auth'
import { AuthApi } from '../../api'
import { TAction } from '@types';

export function* watchAuth() {
  yield takeLatest(SIGN_IN, signInAsync)
  yield takeLatest(LOG_OUT, logOutAction)
}

export function* signInAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { data } = yield call(AuthApi.login, action.data)
    yield put(setToken(data.token))
    yield put(setProfileData(data.user))
  } catch (err) {
    yield put(setError(err))
  }
}

export function* logOutAction() {
  yield put(setLoading(true))
  yield put(setError(null))
  yield put(setProfileData(null))
  yield put(setToken(null))
  yield put(setCurrentClub(undefined))
  yield put(setLoading(false))
}
