import styled from 'styled-components'
import { ManOutlined, WomanOutlined, TeamOutlined, MehOutlined } from '@ant-design/icons';
import { COLORS } from '../../../../constants/colors';

export const PercentContainer = styled.div`
  display: flex;
  width: 100%;
`

export const PercentValue = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  &:not(:last-child) {
    margin-right: 3rem;
  }

  .value {
    font-size: 1rem;
    margin-left: 0.3rem;
  }
`

export const ManIcon = styled(ManOutlined)`
  color: ${COLORS.BLUE}
`
export const WomanIcon = styled(WomanOutlined)`
  color: ${COLORS.RED}
`
export const TeamIcon = styled(TeamOutlined)`
`
export const MehIcon = styled(MehOutlined)`
  color: ${COLORS.ORANGE}
`
