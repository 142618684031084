import { renderAge } from './renderStrings';
import { COLORS } from 'src/constants';
import { IChartItem } from '@types';


export const optimizeChartData = (data: IChartItem[]) => data?.reduce((acc: any, i: any) => {
  if (i.value && i.value !== 0){
    acc.push(i);
  }
  return acc
}, [])

export const getAgesChartOptions = (agesData: IChartItem[]) => {
  const optimizedData = optimizeChartData(agesData)

  return ({
    labels: optimizedData?.map((item: any) => ` ${renderAge(item.label)}`),
    datasets: [
      {
        data: optimizedData?.map((item: any) => item.value),
        backgroundColor: [
          COLORS.BLUE,
          COLORS.RED,
          COLORS.GREEN,
          COLORS.PURPLE,
          COLORS.ORANGE,
          COLORS.GREY,
          COLORS.GREEN_LIGHT,
        ],
      },
    ],
  })
}

export const getGendersChartOptions = (gendersData: IChartItem[]) => {
  const optimizedData = optimizeChartData(gendersData)

  return ({
    labels: optimizedData?.map((item: any) => (item.label)),
    datasets: [
      {
        data: optimizedData?.map((item: any) => item.value),
        backgroundColor: [
          COLORS.BLUE,
          COLORS.RED,
          COLORS.GREEN,
          COLORS.PURPLE,
          COLORS.ORANGE,
          COLORS.GREY,
          COLORS.GREEN_LIGHT,
        ],
      },
    ],
  })
};
