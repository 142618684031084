import {
  SET_LOADING,
  SET_CURRENT_CLUB,
  SET_CLUBS_LIST,
  SET_DATES_RANGE,
  SET_REPORTS_LIST,
  UPDATE_CURRENT_PAGE, 
  SET_ERROR, 
  SET_REPORTS_STATISTIC, 
  SET_NOTIFICATION,
  SET_IS_REPORT_DELETED,
} from './actions'
import { TCommonReducer } from './types';
import { TAction } from '@types';
import moment from 'moment';

export const initialState = {
  loading: false,
  error: null,
  notification: null,
  currentClub: undefined,
  reportsStatistic: undefined,
  clubsList: [],
  datesRange: [moment().subtract(1, 'months'), moment()],
  reportsList: { data: [], count: 0},
  currentPage: 0,
  selectedDateIndices: [false, false] as [boolean, boolean],
  isReportDeleted: false,
}

export const commonReducer = (state: TCommonReducer = initialState, action: TAction) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.data }
    case SET_ERROR:
      return { ...state, error: action.data }
    case SET_CURRENT_CLUB:
      return {
        ...state,
        currentClub: action.data,
      }
    case SET_CLUBS_LIST: {
      return {
        ...state,
        clubsList: action.data,
      }
    }
    case SET_REPORTS_LIST: {
      return {
        ...state,
        reportsList: action.data,
      }
    }
    case SET_REPORTS_STATISTIC: {
      return {
        ...state,
        reportsStatistic: action.data,
      }
    }
    case SET_DATES_RANGE: {
      const datesRange = [...action.data];
      let selectedDateIndices: [boolean, boolean] = [...state.selectedDateIndices];
      
      const isSelectedLeft = Number(action.data[0]) !== Number(state.datesRange[0]);
      const isSelectedRight = Number(action.data[1]) !== Number(state.datesRange[1]);

      //both false
      const shouldAdjustNeighbourDate = !selectedDateIndices.some((el) => Boolean(el))
      if (shouldAdjustNeighbourDate) {
        if (isSelectedLeft) {
          datesRange[1] = datesRange[0].clone()
        } else if (isSelectedRight) {
          datesRange[0] = datesRange[1].clone()
        }
      } 

      if (isSelectedLeft) {
        selectedDateIndices[0] = true;
      } else if (isSelectedRight) {
        selectedDateIndices[1] = true;
      }

      const shouldReset = selectedDateIndices.every((el) => Boolean(el))
      if (shouldReset) {
        selectedDateIndices = [false, false];
      }

      return {
        ...state,
        datesRange,
        selectedDateIndices,
      }
    }
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      }
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.data
      }
    case SET_IS_REPORT_DELETED:
      return {
        ...state,
        isReportDeleted: action.data
      }
    default:
      return { ...state }
  }
}
