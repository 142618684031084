import React from 'react';
import { FieldName, FieldValue, InfoTable, InfoRow, Indent } from './styled';
import { ISocialMediaPost } from '../../../redux/dailyReport';

type TProps = {
  post: ISocialMediaPost | null
}

const SocialPostInfo = ({ post }: TProps): JSX.Element => {
  return (
    <InfoTable>
      <InfoRow>
        <FieldName>
          Länk till inlägg på sociala medier :
        </FieldName>
        <FieldValue>
          {post?.url}
        </FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>
          Antal likes :
        </FieldName>
        <FieldValue>
          {post?.likes}
        </FieldValue>
      </InfoRow>
      <InfoRow>
        <FieldName>
          Antal visningar :
        </FieldName>
        <FieldValue>
          {post?.views}
        </FieldValue>
      </InfoRow>
      <Indent>
        <div dangerouslySetInnerHTML={{ __html: post?.description || '' }} />
      </Indent>
    </InfoTable>
  )
}

export default SocialPostInfo
