import React from 'react';
import { StubWrap, StubText } from './styled';
import { useTranslation } from 'react-i18next';

export const Stub = (): JSX.Element => {
	const { t } = useTranslation()

	return (
		<StubWrap>
			<StubText>
				{t('common.no_data')}
			</StubText>
		</StubWrap>
	)
}
