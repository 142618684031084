import styled from 'styled-components'
import { COLORS } from '../../../constants/colors';

export const MainBlock = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${COLORS.WHITE};
  border-radius: 0 0 0.5rem 0.5rem;
`

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin: 2rem 0 ;
`
