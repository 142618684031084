import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { initiativeReportReducerSelector } from 'src/redux/dailyReport/selectors';
import { addInitiativeReport, IInitiativeReport } from 'src/redux/initiativeReport';
import { getInitiativeTypesList } from 'src/redux/initiativeReport/actions';

import { AddPostBlock } from '../DailyReport/styled';
import { InitiativeReportForm } from './InitiativeReportForm';
import { initialInitiativeReport } from '../../../../redux/initiativeReport/reducer';
import { dailyReportReducerSelector } from '../../../../redux/dailyReport';

const InitiativeReport = ({ initiativeReports }: {initiativeReports: IInitiativeReport[]}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()

  const onAddInitiativeReport = () => {
    dispatch(addInitiativeReport({ ...initialInitiativeReport, id: uuid() }))
  }

  const { initiativeTypesList } = useAppSelector(initiativeReportReducerSelector)

  const { currentDailyReport, newReport } = useAppSelector(dailyReportReducerSelector)
  const club = newReport.clubId || currentDailyReport.clubId;

	useEffect(() => {
		dispatch(getInitiativeTypesList(club))
	}, [club])

  const mapReports = (report: any) => {
		let type = report.type;

		if (report.typeId) {
			type = report.typeId;
		}
		const updatedReport = {
			...report,
			type,
		}
		return (
			<InitiativeReportForm
				report={updatedReport}
				initiativeTypesList={initiativeTypesList}
				reportsLength={initiativeReports.length}
				key={report.id}
			/>
		);
	}

	const mappedReports = useMemo(() => initiativeReports?.map(mapReports), [
		initiativeReports,
		initiativeTypesList,
	])

  return (
    <>
      {mappedReports}
			<AddPostBlock onClick={onAddInitiativeReport}>
				<PlusOutlined className="icon"/>
				{t('third_tab.add_initiative')}
			</AddPostBlock>
    </>

  )
}

export default InitiativeReport
