import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationSW from "./locales/sw/translation.json";

export const resources = {
  sw: {
    translation: translationSW,
  },
  en: {
    translation: translationEN,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "sw",
  resources,
});
