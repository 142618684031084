import React from 'react';
import { useTranslation } from 'react-i18next';
import exit from '../../assets/svg/exit.svg';
import { useAppDispatch } from 'src/hooks';
import { logOut } from '../../redux/auth/actions';
import { StyledLogoutButton, Title } from './styled';

const LogoutButton = () => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation()
	const onLogOut = () => {
		dispatch(logOut())
	}

	return <StyledLogoutButton onClick={onLogOut}>
		<Title>
			{t('auth.log_out')}
		</Title>
		<img src={exit}/>
	</StyledLogoutButton>
}

export default LogoutButton
