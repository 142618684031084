import React from 'react';
import { toNumber } from 'lodash';
import { DoughnutChart, VerticalChart } from 'src/components';
import { ChartsWrap, ChartsBlock, ChartsTitle, ChartWrap, ChartMainWrap } from './styled';
import { COLORS } from 'src/constants';
import {getAgesChartOptions, getGendersChartOptions} from "../../../helpers/charts";
import {useTranslation} from "react-i18next";
import {getVisitorsTooltip} from "src/services";
import {chartDataTypes} from "../../../constants/charts";


type GenderStatistic = {
  amount: number,
  percentage: number,
}

type StatisticResult = {
  boys: GenderStatistic,
  girls: GenderStatistic,
  nonGender: GenderStatistic,
  date: string,
  incident_types: any[],
  incidents: number,
  initiative_types: any[],
  initiatives: number,
  total: number,
}

type TProps = {
  reportsStatistic: {
    ages: any,
    results: StatisticResult[],
    dateTo: string,
    dateFrom: string,
  }
}

const countGenderAmouns = (statisticResults: StatisticResult[], gender: string): number | undefined => {
  if (!statisticResults) {
    return undefined;
  }
  return statisticResults.reduce((acc: number, current: any) => acc + current[gender].amount, 0);
}

const ListCharts = ({ reportsStatistic }: TProps): JSX.Element => {
  const { t } = useTranslation()

  const gendersData = [
    {
      value: countGenderAmouns(reportsStatistic?.results, 'boys'),
      label: t('common.boys')
    },
    {
      value: countGenderAmouns(reportsStatistic?.results, 'girls'),
      label: t('common.girls')
    },
    {
      value: countGenderAmouns(reportsStatistic?.results, 'nonGender'),
      label: t('common.non_gender')
    },
  ]

  const agesData = reportsStatistic?.ages?.map((item: {age: number, ageCount: number}) => ({
    value: item.ageCount,
    label: item.age
  }))

  const totalVisitorsData = reportsStatistic?.results.map((result: StatisticResult) => {
    return {
      label: result.date,
      value: (
        toNumber(result.boys.amount)
        + toNumber(result.girls.amount)
        + toNumber(result.nonGender.amount)
      ),
    };
  });

  const incidentsData = reportsStatistic?.results.map((result: StatisticResult) => {
    return {
      label: result.date,
      value: result.incidents,
    };
  });

  const initiativesData = reportsStatistic?.results.map((result: StatisticResult) => {
    return {
      label: result.date,
      value: result.initiatives,
    };
  });

  return (
    <ChartsWrap>
      <ChartMainWrap>
        <ChartsTitle>
          {t('common.visitors_chart_title')}
        </ChartsTitle>
        <VerticalChart
          color={COLORS.BLUE}
          dataset={totalVisitorsData}
          dataType={chartDataTypes.VISITORS}
        />
      </ChartMainWrap>
      <ChartsBlock>
        <ChartWrap>
          <ChartsTitle>
            {t('common.ages_chart_title')}
          </ChartsTitle>
          <DoughnutChart
            data={getAgesChartOptions(agesData)}
          />
        </ChartWrap>
        <ChartWrap>
          <ChartsTitle>
            {t('common.genders_chart_title')}
          </ChartsTitle>
          <DoughnutChart
            data={getGendersChartOptions(gendersData)}
          />
        </ChartWrap>
      </ChartsBlock>
      <ChartsBlock>
        <ChartWrap>
          <ChartsTitle>
            {t('common.incidents_chart_title')}
          </ChartsTitle>
          <VerticalChart
            color={COLORS.RED}
            dataset={incidentsData}
            dataType={chartDataTypes.INCIDENTS}
          />
        </ChartWrap>
        <ChartWrap>
          <ChartsTitle>
            {t('common.initiatives_chart_title')}
          </ChartsTitle>
          <VerticalChart
            color={COLORS.GREEN_LIGHT}
            dataset={initiativesData}
            dataType={chartDataTypes.INITIATIVES}
          />
        </ChartWrap>
      </ChartsBlock>
    </ChartsWrap>
  )
}

export default ListCharts
