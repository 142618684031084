import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getCurrentReport } from '../redux/dailyReport';

export const useRefreshCurrentReport = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if(id){
      dispatch(getCurrentReport(id))
    }
  }, [])
}
