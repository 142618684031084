import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPostBlock } from '../DailyReport/styled';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { addIncidentReport, getIncidentTypesList, getStaffList } from 'src/redux/incidentReport/actions';
import { commonReducerSelector } from 'src/redux/common';
import { incidentReportReducerSelector } from 'src/redux/incidentReport/selectors';
import { IIncidentReport } from 'src/redux/incidentReport/types';
import { getStaffSelectTree, formatIncidentAndInitiativeTypes } from 'src/helpers/dataFormat';
import { IncidentForm } from './IncidentForm';
import {dailyReportReducer, dailyReportReducerSelector, IDailyReport} from "../../../../redux/dailyReport";

type TProps = {
	reportData: IDailyReport,
	incidentReports: IIncidentReport[],
}

const IncidentReport = ({ incidentReports, reportData }: TProps): JSX.Element => {
	const { currentDailyReport, newReport } = useAppSelector(dailyReportReducerSelector)
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { staffList, incidentTypesList } = useAppSelector(incidentReportReducerSelector)

	const staffTree = getStaffSelectTree(staffList)
	const onAddIncidentReport = () => {
		dispatch(addIncidentReport({ id: uuid() }))
	}
	const club = newReport.clubId || currentDailyReport.clubId;
	useEffect(() => {
		dispatch(getStaffList(club))
	}, [])

	useEffect(() => {
		if (club.length){
			dispatch(getIncidentTypesList(club))
		}
	}, [club])

	const mapReports = (report: IIncidentReport) => {
		let reporter: any = report.reporter;
		let type = report.type;
		// duck typing
		if (report.reporterId) {
			reporter = report.reporterId;
		}
		if (report.typeId) {
			type = report.typeId;
		}
		const updatedReport = {
			...report,
			reporter,
			type,
		}
		return (
			<IncidentForm
				report={updatedReport}
				incidentTypesList={formatIncidentAndInitiativeTypes(incidentTypesList)}
				staffTree={staffTree}
				reportsLength={incidentReports.length}
				key={report.id}
			/>
		);
	}
	const mappedReports = useMemo(() => incidentReports?.map(mapReports), [
		incidentReports,
		incidentTypesList,
		staffTree,
	])

	return (
		<>
			{mappedReports}
			<AddPostBlock onClick={onAddIncidentReport}>
				<PlusOutlined className="icon"/>
				{t('incidentReport.add_incident')}
			</AddPostBlock>
		</>
	)
}

export default IncidentReport
