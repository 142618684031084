import styled from 'styled-components'

export const MainContainer = styled.div`
  max-width: 1240px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const InfoText = styled.p`
  font-size: 1rem;
  font-weight: bold;
  max-width: 50%;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`
