import { Formik } from 'formik';
import React, { FormEvent } from 'react';
import { TextArea } from 'src/components';
import { EVENT_FORM_FIELDS } from '../../../../constants/forms';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'src/hooks';
import { removeOnlineEvent, IOnlineEvent } from 'src/redux/dailyReport';
import TextInput from '../../../formControlls/TextInput';
import FieldWrap from '../../../FieldWrap';
import { updateOnlineEvent } from '../../../../redux/dailyReport/actions';
import { DeleteBlock, SectionText, TextAreaBlock } from './styled';

const {
  EVENT_NAME,
  EVENT_VISITORS_COUNT,
  EVENT_GIRLS_PERCENT,
  EVENT_BOYS_PERCENT,
  EVENT_NON_GENDER_PERCENT,
  EVENT_NOTE
} = EVENT_FORM_FIELDS

interface IProps {
  event : IOnlineEvent
}

const initialValues = {
  [EVENT_NAME]: '',
  [EVENT_VISITORS_COUNT]: '',
  [EVENT_GIRLS_PERCENT]: '',
  [EVENT_BOYS_PERCENT]: '',
  [EVENT_NON_GENDER_PERCENT]: '',
  [EVENT_NOTE]: ''
}

export const OnlineEvent = ({ event }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const removeEvent = () => {
    dispatch(removeOnlineEvent({ id: event.id }))
  }

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { value, name } = e.target as HTMLTextAreaElement
    dispatch(updateOnlineEvent({ id: event.id, value, name }))
  }

  const onFieldChange = (name: string, value: any) => {
    dispatch(updateOnlineEvent({ id: event.id, value, name }))
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={() => console.log('')}
  >
    {({ handleChange }) => {

      return <>
        <DeleteBlock>
          <SectionText>
            Evenemang
          </SectionText>
          <button className="delete" onClick={removeEvent}>
            <DeleteOutlined/>
            <span>Radera</span>
          </button>
        </DeleteBlock>
        <form noValidate onChange={onFormChange}>
          <TextInput
            placeholder={t('dailyReport.event_name_label')}
            name={EVENT_NAME}
            value={event[EVENT_NAME]}
            handleChange={handleChange}
          />
          <FieldWrap label={t('dailyReport.visitors_number_info')}>
            <TextInput
              placeholder={t('dailyReport.visitors_number_placeholder')}
              name={EVENT_VISITORS_COUNT}
              value={event[EVENT_VISITORS_COUNT]}
              handleChange={handleChange}
            />
          </FieldWrap>
          <FieldWrap label={t('online_event.guys_percent_info')}>
            <TextInput
              placeholder={t('dailyReport.guys_percent_placeholder')}
              name={EVENT_BOYS_PERCENT}
              value={event[EVENT_BOYS_PERCENT]}
              handleChange={handleChange}
            />
          </FieldWrap>
          <FieldWrap label={t('online_event.girls_percent_info')}>
            <TextInput
              placeholder={t('dailyReport.girls_percent_placeholder')}
              name={EVENT_GIRLS_PERCENT}
              value={event[EVENT_GIRLS_PERCENT]}
              handleChange={handleChange}
            />
          </FieldWrap>
          <FieldWrap label={t('online_event.non_gender_percent_info')}>
            <TextInput
              placeholder={t('dailyReport.non_gender_placeholder')}
              name={EVENT_NON_GENDER_PERCENT}
              value={event[EVENT_NON_GENDER_PERCENT]}
              handleChange={handleChange}
            />
          </FieldWrap>
          <TextAreaBlock>
            <TextArea
              name={EVENT_NOTE}
              handleChange={onFieldChange}
              value={event[EVENT_NOTE]}
            />
          </TextAreaBlock>
        </form>
      </>
    }}
  </Formik>
}
