import styled from 'styled-components'
import { COLORS } from 'src/constants';


export const ChartsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const ChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 49%;
  background: #fff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px #D0D4E6;
`

export const ChartsBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: ${COLORS.BACKGROUND_GREY};
  padding: 24px 0;
`

export const InfoText = styled.p`
  font-size: 1rem;
  font-weight: bold;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`

export const InfoDate = styled.div`
  color: ${COLORS.BLUE}
`
