import { TreeSelect } from 'antd';
import styled from 'styled-components';

export const styles = {
  dropdownStyle: {
    maxHeight: 400,
    overflow: 'auto'
  },
}

export const StyledSelectTree = styled(TreeSelect)`
  width: 100%;
  max-width: 292px;
  
  .ant-select-selector {
    border: none !important;
    border-bottom: 1.5px solid #AEB3B7 !important;
  }
`
