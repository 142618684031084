import React from 'react';
import { DoughnutChart } from 'src/components';
import { ChartsTitle } from '../ReportInfo/styled';
import { ChartsWrap, ChartsBlock, ChartWrap } from './styled';
import { getAgesChartOptions, getGendersChartOptions } from '../../../helpers/charts';
import { useTranslation } from 'react-i18next';

type TProps = {
  report: any
}

const ReportHeader = ({ report }: TProps): JSX.Element => {
  const { t } = useTranslation()

  const gendersData = [
    {
      value: report?.boys,
      label: t('common.boys')
    },
    {
      value: report?.girls,
      label: t('common.girls')
    },
    {
      value: report?.nonGender,
      label: t('common.non_gender')
    },
  ]

  const agesData = report?.ages?.map((item: {age: number, ageCount: number}) => ({
    value: item.ageCount,
    label: item.age
  }))


  return (
    <ChartsWrap>
      <ChartsBlock>
        <ChartWrap>
          <ChartsTitle>
            Målgrupper
          </ChartsTitle>
          <DoughnutChart
            data={getGendersChartOptions(gendersData)}
          />
        </ChartWrap>
        <ChartWrap>
          <ChartsTitle>
            Åldersgrupper
          </ChartsTitle>
          <DoughnutChart
            data={getAgesChartOptions(agesData)}
          />
        </ChartWrap>
      </ChartsBlock>
    </ChartsWrap>
  )
}

export default ReportHeader
