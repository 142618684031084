import styled from 'styled-components'
import { COLORS } from 'src/constants';


export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  width: 100%;
  padding: 40px;
  align-items: flex-start;
  box-shadow: 0px 1px 4px #D0D4E6;
`

export const ChartsWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  width: 100%;
  padding: 40px;
  align-items: flex-start;
  margin-top: 2rem;
`

export const BlockSubtitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: ${COLORS.BLUE};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
`

export const BlockTitle = styled(BlockSubtitle)`
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
`

export const FieldName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-right: 0.35em;
  color: ${COLORS.FONT_GREY};
`
export const FieldValue = styled(FieldName)`
  color: ${COLORS.MAIN_TEXT}
`

export const Indent = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${COLORS.MAIN_TEXT}
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`

export const IndentTitle = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: ${COLORS.MAIN_TEXT}
`

export const ChartsTitle = styled(IndentTitle)`
  font-size: 16px;
  line-height: 18px;
  align-self: flex-start;
  margin-bottom: 20px;
`

export const InfoTable = styled.h3`
  display: flex;
  flex-direction: column;
`
export const InfoRow = styled.h3`
  display: flex;
`
