import styled from 'styled-components'
import { COLORS } from 'src/constants';
import { Breadcrumb as AntdBreadcrumb } from 'antd';


export const Breadcrumbs = styled(AntdBreadcrumb)`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 24px 0;
  justify-content: flex-start;
  align-items: center;
  padding: 0.35em;
  border-radius: 8px;
`

export const Breadcrumb = styled(AntdBreadcrumb.Item)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.BLUE} !important;
`

export const LastBreadcrumb = styled('span')`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.MAIN_TEXT};
`

export const CurrentBreadcrumb = styled('span')`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.MAIN_TEXT};
`
