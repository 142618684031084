import styled from 'styled-components'
import { COLORS } from 'src/constants';

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 0.5rem;
  margin: 0.5rem auto;
  cursor: pointer;
  
  a {
    display: flex;
    width: 100%;
    padding: 25px;
  }
  
  &:hover {
    background-color: ${COLORS.LIGHT_BLUE}
  }
`

export const StyledDate = styled.div`
  min-width: 300px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.MAIN_TEXT};
`
