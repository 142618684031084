import styled from 'styled-components'


export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonsWrap = styled.div`
  display: flex;
  .space {
    margin-right: 0.5rem;
  }
`

export const InfoText = styled.p`
  font-size: 1rem;
  font-weight: bold;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`
