import React, {useEffect} from 'react';
import moment from 'moment';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Stub } from '../Stub';
import i18n from "i18next";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
);

const getLabel = (dateString: string): string => {
	return moment.utc(dateString).format('DD/MM');
}


type TProps = {
	color: string,
	dataType?: string,
	dataset: {
		label: string,
		value: number,
	}[],
}


export const VerticalChart = ({ color, dataset, dataType }: TProps) => {
	const data = dataset?.reduce((acc: any, i: any) => {
		if (i.value && i.value !== 0){
			acc.push(i.value);
		}
		return acc
	}, [])

	const options = {
		responsive: true,
		plugins: {
			tooltip: {
				callbacks: {
					label: (tooltipItem: any): string => {
						return `${i18n.t(`charts.${dataType}`)}: ${data[tooltipItem.dataIndex]}`;
					},
				}
			},
			legend: {
				display: false,
			},
		},
		maxBarThickness: 150,
	}

	const chartData = {
		labels: dataset
			?.filter(i => i.value && i.value !== 0)
			.map(i => getLabel(i.label)),
		datasets: [
			{
				data,
				backgroundColor: color,
			},
		],
	};

	const isEmpty = !data?.length

	return isEmpty ? <Stub/> : <Bar options={options} data={chartData} />;
}
