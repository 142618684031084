import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBordered, ButtonContainer } from '../../common/Button/styled';
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import { TreeSelect } from '../../common/TreeSelect';
import { getClubsSelectTree } from '../../../helpers/dataFormat';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { commonReducerSelector } from '../../../redux/common';
import { setCurrentClub, setDatesRange } from '../../../redux/common/actions';
import { COLORS } from '../../../constants/colors';
import { Menu, Title, ButtonMenu } from './styled';
import RangeDatePicker from '../../common/RangeDatePicker';
import { Moment } from 'moment';
import {setEditReportId} from "../../../redux/dailyReport/actions";

const ReportHeader = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const { clubsList, currentClub, datesRange } = useAppSelector(commonReducerSelector)
  const clubsTree = useMemo(() => getClubsSelectTree(clubsList), [clubsList])

  const createNew = () => {
    dispatch(setEditReportId(null))
    history.push('/create')
  }

  const onClubChange = (v: string) => dispatch(setCurrentClub(v))
  const onRangeChange = (v: Moment[]) => dispatch(setDatesRange(v))

  return (
    <Menu>
      <Title>{t('common.daily_reports')}</Title>

      <ButtonMenu>
        <ButtonContainer className="space">
          <TreeSelect
            treeData={clubsTree}
            handleChange={onClubChange}
            value={currentClub}
            placeholder={t('common.youthClub')}
          />
        </ButtonContainer>

        <ButtonContainer className="space">
          <RangeDatePicker
            handleChange={onRangeChange}
            value={datesRange}
            // placeholder={<><FilterOutlined />Filter date</>}
          />
        </ButtonContainer>

        <ButtonBordered
          type="primary"
          icon={<PlusOutlined/>}
          bgColor={COLORS.BLUE}
          onClick={createNew}
        >
          {t('common.create_new')}
        </ButtonBordered>
      </ButtonMenu>
    </Menu>
  )
}

export default ReportHeader
