import React, { useMemo } from 'react';
import { useAppDispatch } from 'src/hooks';
import { IDailyReport, IOnlineEvent } from '../../../../redux/dailyReport';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { addOnlineEvent } from '../../../../redux/dailyReport/actions';
import { OnlineEvent } from './OnlineEvent';
import { AddPostBlock } from './styled';
import { useTranslation } from 'react-i18next';


export const OnlineEventsBlock = ({ reportData }: { reportData: IDailyReport }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const eventsData = reportData?.onlineEvents

  const onAddEvent = () => {
    dispatch(addOnlineEvent({ id: uuid() }))
  }

  const mapEvents = (event: IOnlineEvent) => {
    return <OnlineEvent key={event.id} event={event}/>
  }

  const mappedEvents = useMemo(() => {
    return eventsData?.map(mapEvents)
  }, [eventsData])

  return (
    <>
      {mappedEvents}
      <AddPostBlock onClick={onAddEvent}>
        <PlusOutlined className="icon"/>
        {t('dailyReport.add_event')}
      </AddPostBlock>
    </>
  )
}
