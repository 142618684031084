import { DAILY_REPORT_FIELDS } from '../constants/forms';
import { isNumber } from 'lodash';

const { DATE, VISITORS, CLUB, BOYS, BOYS_PERCENTS, GIRLS, GIRLS_PERCENTS, NON_GENDER, NON_GENDER_PERCENTS, CASHIER, BALANCE, NOTE } = DAILY_REPORT_FIELDS

export const getStaffSelectTree = (arr: any[] | null) => {
  const mapStaff = (staff: any) => ({
    title: `${staff.firstName} ${staff.lastName}`,
    value: staff.id,
  })

  const mapClubs = (club: any) => ({
    title: club?.name,
    children: club?.staff?.map(mapStaff)
  })

  const filterWithStaffOnly = (item: any) => item.municipality?.clubs?.some((club: any) => !!club.staff.length)

  return arr?.filter(filterWithStaffOnly).map((i: any) => ({
    title: i?.municipality?.name,
    children: i.municipality.clubs?.map(mapClubs)
  }))
}

export const formatIncidentAndInitiativeTypes = (list: any): any[] => {
  return (list || []).map((item: any) => ({
    label: item.value, 
    value: item.id,
  }));
}

export const getIncidentTypesSelectTree = (arr: any[] | null): { title: any, id: any}[]  => {
  if (!arr) {
    return [];
  }
  return arr.map((i: any) => ({ title: i.value, id: i.id}));
}

export const getClubsSelectTree = (arr: any[] | null) => {
  const mapClubs = (club: any) => ({
    title: club?.name,
    value: club?.id
  })

  const filterWithClubsOnly = (item: any) => !!item.municipality?.clubs?.length

  return arr?.filter(filterWithClubsOnly).map((i: any) => ({
    title: i?.municipality?.name,
    children: i.municipality.clubs?.map(mapClubs)
  }))
}

export const getPercents = (base: number, value: number | null) :number => {
  if (value === null) {
    return 0
  } else {
    return (value / base) * 100
  }
}

export const getTotalUsersAmount = (initial: any) => {
  return (parseInt(initial[BOYS]) || 0) + (parseInt(initial[GIRLS]) || 0) + (parseInt(initial[NON_GENDER]) || 0)
}


export const getUpdatedReport = (initial: any, name: string, value: number, total: number) => {
  switch (true){
    case name === BOYS:
      return {
        ...initial,
        [BOYS]: value,
        [BOYS_PERCENTS]: getPercents(total, value),
        [GIRLS_PERCENTS]: isNumber(initial[GIRLS_PERCENTS]) ? getPercents(total, parseInt(initial[GIRLS_PERCENTS])) : 0,
        [NON_GENDER_PERCENTS]: isNumber(initial[GIRLS_PERCENTS]) ? getPercents(total, parseInt(initial[NON_GENDER_PERCENTS])) : 0,
        [VISITORS]: total
      }
    case name === GIRLS:
      return {
        ...initial,
        [GIRLS]: value,
        [GIRLS_PERCENTS]: getPercents(total, value),
        [BOYS_PERCENTS]: isNumber(initial[GIRLS_PERCENTS]) ? getPercents(total, parseInt(initial[BOYS_PERCENTS])) : 0,
        [NON_GENDER_PERCENTS]: isNumber(initial[GIRLS_PERCENTS]) ? getPercents(total, parseInt(initial[NON_GENDER_PERCENTS])) : 0,
        [VISITORS]: total
      }
    case name === NON_GENDER:
      return {
        ...initial,
        [NON_GENDER]: value,
        [NON_GENDER_PERCENTS]: getPercents(total, value),
        [BOYS_PERCENTS]: isNumber(initial[GIRLS_PERCENTS]) ? getPercents(total, parseInt(initial[BOYS_PERCENTS])) : 0,
        [GIRLS_PERCENTS]: isNumber(initial[GIRLS_PERCENTS]) ? getPercents(total, parseInt(initial[GIRLS_PERCENTS])) : 0,
        [VISITORS]: total
      }
    default: return { ...initial, [name]: value }
  }
}
