import { Formik } from 'formik';
import React, { FormEvent, useEffect } from 'react';
import { TextArea } from 'src/components';
import { INCIDENT_REPORT_FIELDS } from '../../../../constants/forms';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'src/hooks';
import { removeIncidentReport, updateIncidentReport } from '../../../../redux/incidentReport/actions';
import { DeleteBlock } from '../DailyReport/styled';
import { TreeSelect, Select } from 'src/components';
import FieldWrap from '../../../FieldWrap';
import { TextAreaBlock, StyledForm } from './styled';

const {
  REPORTER,
  INCIDENT_TYPE,
  DESCRIPTION,
  ACTION,
} = INCIDENT_REPORT_FIELDS

interface IProps {
  report: any
  staffTree: any
  incidentTypesList: any
  reportsLength: number
}

const initialValues = {
  [REPORTER]: undefined,
  [INCIDENT_TYPE]: undefined,
  [DESCRIPTION]: '',
  [ACTION]: '',
}

export const IncidentForm = ({ report, staffTree, incidentTypesList, reportsLength }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onRemoveIncidentReport = () => {
    dispatch(removeIncidentReport({ id: report.id }))
  }

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { value, name } = e.target as HTMLTextAreaElement
    dispatch(updateIncidentReport({ id: report.id, value, name }))
  }

  const onTextAreaChange = (name: string, value: string) => {
    dispatch(updateIncidentReport({ id: report.id, value, name }))
  }

  const onDropdownChange = (name: string, value: string): void => {
    dispatch(updateIncidentReport({ id: report.id, value, name }))
  }

  useEffect(() => {
    if(report[INCIDENT_TYPE]){
      const containCurrentType = incidentTypesList.find((i: any) => i.value === report[INCIDENT_TYPE])
      !containCurrentType?.value && dispatch(updateIncidentReport({ id: report.id, value: '', name: INCIDENT_TYPE }))
    }
  }, [report[INCIDENT_TYPE], incidentTypesList])

  return <Formik
    initialValues={initialValues}
    onSubmit={() => console.log('')}
  >
    {({ handleChange }) => {
      return <>
        {reportsLength !== 1 &&<DeleteBlock>
          <button className="delete" onClick={onRemoveIncidentReport}>
            <DeleteOutlined/>
            <span>Radera</span>
          </button>
        </DeleteBlock>}
        <StyledForm onChange={onFormChange}>
          <FieldWrap>
            <TreeSelect
              treeData={staffTree}
              name={REPORTER}
              value={report[REPORTER]}
              handleChange={onDropdownChange}
              placeholder={t('incidentReport.author')}
            />
          </FieldWrap>
          <FieldWrap>
            <Select
              list={incidentTypesList}
              name={INCIDENT_TYPE}
              value={report[INCIDENT_TYPE]}
              handleChange={onDropdownChange}
              placeholder={t('incidentReport.incident_type')}
            />
          </FieldWrap>
          <TextAreaBlock>
            <TextArea
              name={DESCRIPTION}
              value={report[DESCRIPTION]}
              handleChange={onTextAreaChange}
              placeholder={t('incidentReport.description')}
            />
          </TextAreaBlock>
          <TextAreaBlock>
            <TextArea
              name={ACTION}
              value={report[ACTION]}
              handleChange={onTextAreaChange}
              placeholder={t('incidentReport.actions')}
            />
          </TextAreaBlock>
        </StyledForm>
      </>
    }}
  </Formik>
}
