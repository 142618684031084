import styled from 'styled-components'
import { COLORS } from 'src/constants';

type TProps = {
    bgColor?: string,
    color?: string,
}

export const StubWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const StubText = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: ${COLORS.FONT_GREY};
  text-align: center;
  width: 100%;
  text-transform: uppercase;
`
