import { httpGet } from '../services'

const IncidentReportApi = {
	getStaffList() {
		return httpGet(`/analytics/staff`)
	},
	getIncidentTypesList(clubId) {
		return httpGet(`/analytics/incidentTypes/${clubId}`)
	},
}

export default IncidentReportApi
