import { all } from 'redux-saga/effects'
import { watchAuth } from './auth/sagas';
import { watchDailyReport } from './dailyReport/sagas';
import { watchIncidentReport } from './incidentReport/sagas';
import { watchInitiativeReport } from './initiativeReport/sagas';
import { watchCommon } from './common/sagas';


export default function* rootSaga() {
	yield all([
		watchAuth(),
		watchDailyReport(),
		watchIncidentReport(),
		watchInitiativeReport(),
		watchCommon()
	])
}
