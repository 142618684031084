import styled from 'styled-components'
import { COLORS } from 'src/constants';


export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`

export const ButtonMenu = styled.div`
  display: flex;
  .space {
    margin-right: 0.5rem;
  }
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
`

