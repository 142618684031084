import {
	SET_ERROR,
	SET_LOADING,
	SET_TOKEN,
	SET_PROFILE_DATA, LOG_OUT
} from './actions'
import { TAuthState } from './types';
import { TAction } from '@types';

export const initialState = {
	loading: false,
	token: null,
	error: null,
	profileData: null,
}

export const authReducer = (state: TAuthState = initialState, action: TAction) => {
	switch (action.type) {
	case SET_LOADING:
		return { ...state, loading: action.data }
	case SET_TOKEN:
		return {
			...state,
			token: action.data,
		}
	case SET_ERROR: {
		return {
			...state,
			error: action.data,
		}
	}
	case SET_PROFILE_DATA:
		return {
			...state, profileData: action.data
		}
	case LOG_OUT:
		return initialState
	default:
		return state
	}
}
