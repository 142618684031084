import styled from 'styled-components'
import { COLORS } from '../../../../constants/colors';

export const ButtonsContainer = styled.div`
  min-width: 300px;
  display: flex;
`

export const ButtonItem = styled.div`
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:last-child {
    margin: 0 1.5rem 0 1rem;
  }


  .text {
    color: ${COLORS.BLUE};
    margin-left: 0.3rem;
    font-weight: bold;
  }
`
