import styled from 'styled-components'
import { COLORS } from '../../../../constants/colors';

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
`
export const TextAreaBlock = styled.div`
  width: 100%;
  margin: 2rem 0;
`
export const AddPostBlock = styled.div`
  margin: 1rem 0;
  font-size: 1rem;
  cursor: pointer;
  .icon {
    color: ${COLORS.BLUE};
    margin-right: 0.5rem;
  }
`
export const StyledForm = styled.form`
  width: 100%
`
