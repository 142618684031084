import { createStore as reduxCreateStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: ['auth']
}

const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducer)

const createStore = () => {
	const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware))
	const store = reduxCreateStore(persistedReducer, enhancer)
	sagaMiddleware.run(rootSaga)

	const persistor = persistStore(store);
	return { store, persistor };
};


const reduxStore = createStore()

export default reduxStore;

export type TAppDispatch = typeof reduxStore.store.dispatch




