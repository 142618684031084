import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import ReportItem from './ReportItem';
import ListCharts from './ListCharts';
import { commonReducerSelector } from '../../redux/common';
import { deleteReport, getReportsList, getReportsStatistic, setCurrentPage } from '../../redux/common/actions';
import { BlockContainer, InfoText, Title, StyledPagination } from './styled';
import { Modal } from '../common/Modal';
import { formatDate } from 'src/services';
import { dateCommon } from 'src/constants';
import { useTranslation } from 'react-i18next';

const ReportsList = (): JSX.Element => {
	const { reportsList, loading, currentPage, datesRange, currentClub, reportsStatistic } = useAppSelector(commonReducerSelector)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [selectedItem, setSelectedItem] = useState<string>('')
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const hideModal = () => setIsModalVisible(false)
	const showModal = () => setIsModalVisible(true)
	const handleRemove = () => {
		dispatch(deleteReport(selectedItem))
		hideModal()
	}

	const onRemove = (id: string) => {
		setSelectedItem(id)
		showModal()
	}

	const mappedReports = useMemo(() => {
		return reportsList?.data?.map((report: any) => (
			<ReportItem key={report.id} report={report} onRemove={onRemove}/>
		))
	}, [reportsList])

	useEffect(() => {
		const reportsDTO = {
			page: currentPage,
			dateFrom: formatDate(datesRange?.[0], dateCommon),
			dateTo: formatDate(datesRange?.[1], dateCommon),
			clubId: currentClub
		}
		dispatch(getReportsList(reportsDTO))
		if (currentClub){
			dispatch(getReportsStatistic(reportsDTO))
		}
	}, [datesRange?.[0], datesRange?.[1], currentPage, currentClub])

	const onPageChange = (page: number) => {
		dispatch(setCurrentPage(page - 1))
	}

	return (
		<BlockContainer>
			<Title>
				{t('common.list')}
			</Title>
			<InfoText>
				{t('common.list_subtitle')}
			</InfoText>


			<ListCharts reportsStatistic={reportsStatistic} />

			{mappedReports}

			<Modal
				isVisible={isModalVisible}
				onCancel={hideModal}
				loading={loading}
				onConfirm={handleRemove}
				title={t('common.remove_message')}
			/>
			{(reportsList?.count > reportsList.data.length) &&
				<StyledPagination
					defaultCurrent={currentPage + 1}
					total={reportsList?.count}
					onChange={onPageChange}
				/>}
		</BlockContainer>
	)
}

export default ReportsList
