import styled from 'styled-components'
import { Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;

export const StyledSelect = styled(AntdSelect)`
  border: none;
  border-bottom: 1.5px solid #AEB3B7;
  width: 18.25rem;
  border: ${({ isValid }: { isValid: boolean }) => !isValid ? `1px solid red` : ''};

  &&& .ant-select-selector {
    border: none;
  }
`

export const StyledOption = styled(Option)`
  
`
