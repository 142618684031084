import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { dailyReportReducerSelector } from '../../../../redux/dailyReport';
import { addAdditionalItem } from '../../../../redux/dailyReport/actions';
import { AddPostBlock, SectionText } from './styled';
import { AdditionalDataForm } from './AdditionalDataForm';
import { IAdditionalDataItem } from '../../../../redux/dailyReport/types';
import { useTranslation } from 'react-i18next';


export const AdditionalDataBlock = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { additionalDataItems, isAmountValid } = useAppSelector(dailyReportReducerSelector)
  const onAddItem = () => {
    dispatch(addAdditionalItem({ id: uuid() }))
  }

  const isItemValid = isAmountValid && additionalDataItems?.length >= 1
  const mapItems = (item: IAdditionalDataItem, idx: number) => {
    return <AdditionalDataForm key={item.id} item={item} number={idx + 1} isValid={isItemValid} isLast={additionalDataItems.length === 1}/>
  }

  const mappedItems = useMemo(() => {
    return additionalDataItems?.map(mapItems)
  }, [additionalDataItems])

  return (
    <>
      <SectionText>
        {t('dailyReport.additional_forms_title')}
      </SectionText>
      {mappedItems}
      <AddPostBlock onClick={onAddItem}>
        <PlusOutlined className="icon"/>
        {t('dailyReport.add_additional_form')}
      </AddPostBlock>
    </>
  )
}
