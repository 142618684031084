import React, { ChangeEvent } from 'react';
import { StyledInput } from './styled';

interface Props {
  label?: string,
  name: string,
  handleChange: (e: ChangeEvent) => void,
  placeholder?: string,
  handleBlur?: (e: ChangeEvent) => void,
  onFocus?: (e: ChangeEvent) => void,
  id?: string,
  type?: string,
  value?: string,
  disabled?: boolean
  isValid?: boolean
}

const TextInput = ({
  label,
  name,
  handleBlur,
  handleChange,
  onFocus,
  placeholder,
  id,
  value,
  type,
  disabled,
  isValid = true
}: Props): JSX.Element => {
  return (
    <StyledInput
      type={type}
      name={name}
      value={value}
      id={id}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={onFocus}
      disabled={disabled}
      isValid={isValid}
    />
  )
}

export default TextInput
