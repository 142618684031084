import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { activeTabSelector, dailyReportReducerSelector, getCurrentReport } from 'src/redux/dailyReport';
import { getClubsList } from 'src/redux/common';
import { COLORS } from 'src/constants';
import { TABS } from 'src/constants/reports';
import IncidentReports from './IncidentReport';
import DailyReport from './DailyReport'
import InitiativeReport from './InitiativeReport';
import { ButtonBordered } from '../../common/Button/styled';
import {
	getDefaultReportValues,
	sendDailyReport,
	setIsAmountValid
} from '../../../redux/dailyReport/actions';
import { incidentReportReducerSelector } from '../../../redux/incidentReport';
import { initiativeReportReducerSelector } from '../../../redux/initiativeReport';
import { ContentBlock, MainBlock } from './styled';
import { getIncidentTypesList, setIncidentReports } from '../../../redux/incidentReport/actions';
import { setInitiativeReports } from '../../../redux/initiativeReport/actions';
import { DAILY_REPORT_FIELDS } from '../../../constants/forms';
import { validateTotal } from '../../../helpers/counters';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const { DATE, CLUB } = DAILY_REPORT_FIELDS

const CreateForm = (): JSX.Element => {
	const { t } = useTranslation()
	const activeTab  = useAppSelector(activeTabSelector)
	const { newReport, isAmountValid, currentDailyReport, editReportId, prefilledData, isProcessed, additionalDataItems }  = useAppSelector(dailyReportReducerSelector)
	const { incidentReports }  = useAppSelector(incidentReportReducerSelector)
	const { initiativeReports }  = useAppSelector(initiativeReportReducerSelector)

	const isEditMode = !!editReportId
	const reportData = isEditMode ? currentDailyReport : newReport
	const dispatch = useAppDispatch()

	const onSubmit = () => {
		const isValid = validateTotal(prefilledData?.total, reportData.total, additionalDataItems)
		if (isValid) {
			dispatch(sendDailyReport({ isEditMode }))
		} else {
			dispatch(setIsAmountValid(false))
		}
	}

	const showInvalidAmountNote = () => {
		notification['error']({
			message: t('errors.additional_data_title'),
			description: t('errors.additional_data_description'),
		});
	};

	useEffect(() => {
		!isAmountValid && showInvalidAmountNote()
		return () => {
			dispatch(setIsAmountValid(true))
		}
	}, [isAmountValid])

	useEffect(() => {
		if (editReportId){
			dispatch(getCurrentReport(editReportId))
		}
	}, [editReportId])

	useEffect(() => {
		dispatch(getClubsList())
		if (isEditMode) {
			dispatch(setIncidentReports(currentDailyReport.incidents))
			dispatch(setInitiativeReports(currentDailyReport.initiatives))
		}
	}, [reportData?.incidents])

	useEffect(() => {
		if (reportData?.date && reportData?.clubId && !isEditMode){
			dispatch(getDefaultReportValues({
				clubId: reportData[CLUB],
				date: reportData[DATE]
			}))
		}
	}, [isEditMode, reportData?.date, reportData?.clubId])

	useEffect(() => {
		if (reportData?.clubId){
			dispatch(getIncidentTypesList(reportData?.clubId))
		}
	}, [reportData?.clubId])

	const getFocusedTab = () => {
		switch (activeTab) {
		case TABS.FIRST:
			return <DailyReport reportData={reportData} />
		case TABS.SECOND:
			return <IncidentReports incidentReports={incidentReports} reportData={reportData} />
		case TABS.THIRD:
			return <InitiativeReport initiativeReports={initiativeReports}/>
		}
	}

	return (
		<MainBlock>
			<ContentBlock>
				{ getFocusedTab() }
				<ButtonBordered
					type="primary"
					disabled={false}
					bgColor={COLORS.BLUE}
					onClick={onSubmit}
				>
					Spara
				</ButtonBordered>
			</ContentBlock>
		</MainBlock>
	)
}

export default CreateForm
