import { Formik } from 'formik';
import React, { FormEvent } from 'react';
import { Select } from 'src/components';
import { ADDITIONAL_DATA_FIELDS } from '../../../../constants/forms';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'src/hooks';
import { DeleteBlock, SectionText } from './styled';
import TextInput from '../../../formControlls/TextInput';
import FieldWrap from '../../../FieldWrap';
import { removeAdditionalItem, setIsAmountValid, updateAdditionalItem } from '../../../../redux/dailyReport/actions';
import { GENDERS } from '../../../../constants/common';
import { additionalItems } from './validations';

const {
  COUNT,
  AGE,
  GENDER
} = ADDITIONAL_DATA_FIELDS

interface IProps {
  item : any;
  isLast: boolean;
  isValid: boolean;
  number: number;
}

const initialValues = {

}

export const AdditionalDataForm = ({ item, isValid, number }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const removeItem = () => {
    dispatch(removeAdditionalItem({ id: item.id }))
  }

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { value, name } = e.target as HTMLTextAreaElement
    dispatch(setIsAmountValid(true))
    dispatch(updateAdditionalItem({ id: item.id, value, name }))
  }

  const onGenderChange = (name: string, value: string) => {
    dispatch(updateAdditionalItem({ id: item.id, value, name }))
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={() => console.log('')}
    validationSchema={additionalItems}
  >
    {({ handleChange, errors }) => {
      return <>
        <SectionText>
          {`${t('dailyReport.additional_forms_title')} #${number}`}
        </SectionText>
        <DeleteBlock>
          <button className="delete" onClick={removeItem}>
            <DeleteOutlined/>
            <span>Radera</span>
          </button>
        </DeleteBlock>
        <form noValidate onChange={onFormChange}>
          <TextInput
            placeholder={t('dailyReport.age')}
            name={AGE}
            handleChange={handleChange}
            value={item[AGE]}
            isValid={isValid}
          />
          <FieldWrap label={t('dailyReport.amount_info')}>
            <TextInput
              placeholder={t('dailyReport.amount')}
              name={COUNT}
              handleChange={handleChange}
              value={item[COUNT]}
              isValid={isValid}
            />
          </FieldWrap>
          <FieldWrap label={t('dailyReport.gender_info')}>
            <Select
              placeholder={t('dailyReport.gender')}
              name={GENDER}
              handleChange={onGenderChange}
              value={item[GENDER]}
              list={GENDERS}
              isValid={isValid}
            />
          </FieldWrap>
        </form>
      </>
    }}
  </Formik>
}
