import React from 'react';
import { DailyReportForm } from './DailyReportForm';
import { SocialPostsBlock } from './SocialPostsBlock';
import { OnlineEventsBlock } from './OnlineEventsBlock';
import { AdditionalDataBlock } from './AdditionalDataBlock';
import { IDailyReport } from '../../../../redux/dailyReport';

const DailyReport = ({ reportData }: { reportData: IDailyReport }): JSX.Element => {
  return (
  <>
    <DailyReportForm reportData={reportData}/>
    <SocialPostsBlock reportData={reportData} />
    <OnlineEventsBlock reportData={reportData}/>
    <AdditionalDataBlock />
  </>
)
}

export default DailyReport
