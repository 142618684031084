import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ReportWrapper
} from './styled';
import ReportHeader from './ReportHeader';
import ReportCharts from './ReportCharts';
import ReportInfo from './ReportInfo';
import {useAppSelector, useRefreshCurrentReport} from 'src/hooks';
import { dailyReportReducerSelector, getCurrentReport } from '../../redux/dailyReport';

type TReportDetailParams = {
  id: string;
};

const DailyReport = () => {
  const { id } = useParams<TReportDetailParams>()

  useRefreshCurrentReport(id)

  const { currentDailyReport } = useAppSelector(dailyReportReducerSelector)

  return (
    <ReportWrapper>
      <ReportHeader report={currentDailyReport}/>
      <ReportCharts report={currentDailyReport}/>
      <ReportInfo report={currentDailyReport}/>
    </ReportWrapper>
  );
};

export default DailyReport
