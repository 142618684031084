import styled from 'styled-components'

export const ReportWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 0.25rem;
`
