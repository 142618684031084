import { toNumber } from 'lodash';
import React from 'react';
import { ManIcon, PercentContainer, PercentValue, TeamIcon, WomanIcon } from './styled';
import { NonGenderSVG } from "../../../../assets/svg";
import {renderPercent} from "../../../../helpers/renderStrings";

type TProps = {
	boys: number,
	girls: number,
	nonGender: number,
	total: number,
}

const ReportItemPercentBlock = ({ boys, girls, nonGender, total }: TProps): JSX.Element => {
	return (
		<PercentContainer>
			<PercentValue>
				<ManIcon />
				<span className="value">{renderPercent(boys)}</span>
			</PercentValue>

			<PercentValue>
				<WomanIcon />
				<span className="value">{renderPercent(girls)}</span>
			</PercentValue>

			<PercentValue>
				<NonGenderSVG />
				<span className="value">{renderPercent(nonGender)}</span>
			</PercentValue>

			<PercentValue>
				<TeamIcon />
				<span className="value">{`${total ? toNumber(total) : 0}st`}</span>
			</PercentValue>
		</PercentContainer>
	)
}

export default ReportItemPercentBlock
