import React, { useEffect } from 'react';
import { ReportMenu, ReportsList, Notification, BreadCrumbs } from '../components';
import { MainContainer, InfoText } from './styled';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { commonReducerSelector, getClubsList } from '../redux/common';
import { homeBreadcrumbs } from '../constants/navigation';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const dispatch = useAppDispatch()
	const { notification } = useAppSelector(commonReducerSelector)
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(getClubsList())
	}, [])

	return (
		<MainContainer>
			{notification && <Notification id={notification}/>}
			<BreadCrumbs data={homeBreadcrumbs}/>

			<ReportMenu />
			<InfoText>
				{t('common.top_subtitle')}
			</InfoText>
			<ReportsList />
		</MainContainer>
	)}

export default Home
