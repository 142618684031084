import styled from 'styled-components'
import { DatePicker } from 'antd';


export const Date = styled(DatePicker)`
  border: none;
  border-bottom: 1.5px solid #AEB3B7;
  width: 18.25rem;

  &:focus {
    outline: none;
  }
`
