import { TGlobalState } from '@types';

export const activeTabSelector = (state: TGlobalState) => state.dailyReport.activeTab
export const dailyReportReducerSelector = (state: TGlobalState) => state.dailyReport
export const reportClubSelector = (state: TGlobalState) => state.dailyReport.newReport.clubId
export const initiativeReportReducerSelector = (state: TGlobalState) => state.initiativeReport
export const newDailyReportSelector = (state: TGlobalState) => state.dailyReport.newReport
export const currentDailyReportSelector = (state: TGlobalState) => state.dailyReport.currentDailyReport
export const socialPostsSelector = (state: TGlobalState) => state.dailyReport.socialPostsList
export const onlineEventsSelector = (state: TGlobalState) => state.dailyReport.onlineEventsList
