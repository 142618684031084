import { IDailyReport, ISocialMediaPost } from '../../../../redux/dailyReport';
import { SocialMediaPost } from './SocialMediaPost';
import { AddPostBlock } from './styled';
import { PlusOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'src/hooks';
import { addSocialPost } from '../../../../redux/dailyReport/actions';
import { v4 as uuid } from 'uuid';
import {useTranslation} from "react-i18next";


export const SocialPostsBlock = ({ reportData }: { reportData: IDailyReport }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const onAddPost = () => {
    dispatch(addSocialPost({ id: uuid() }))
  }

  const mapPosts = (post: ISocialMediaPost) => {
    return <SocialMediaPost key={post?.id} post={post}/>
  }

  const mappedPosts = useMemo(() => {
    return reportData?.socialMediaPosts?.map(mapPosts)
  }, [reportData?.socialMediaPosts])

  return (
    <>
      {mappedPosts}
      <AddPostBlock onClick={onAddPost}>
        <PlusOutlined className="icon"/>
        {t('dailyReport.add_post')}
      </AddPostBlock>
    </>
  )
}
