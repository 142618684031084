import styled from 'styled-components'
import { Form, Input, Button } from 'antd';

type TProps = {
    label: string
}

export const LoginWrapper = styled.div`
  width: 35rem;
  padding: 24px 0;  
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.25rem;
`

export const FieldWrap = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  position: relative;
`

export const SignTitle = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`

export const FieldLabel = styled.p`
  position: absolute;
  z-index: 2;
  top: 1.175rem;
  left: 3.25rem;
  font-size: 0.75rem;
  color: #AEB3B7
`

export const SignForm = styled(Form)`
  position: relative;
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .ant-input-affix-wrapper {
    min-width: 460px;
    padding: 0;
    padding-left: 1em;
  }
  
  .ant-form-item-explain-error {
    font-size: 12px;
    position: absolute;
    bottom: -10px;
  }
`

export const SignInput = styled(Input)`
  height: 4rem;
  border-radius: 0.25rem;
  margin: 1rem 0;
  padding: 1rem;
  font-size: 1.2rem;

  .site-form-item-icon {
    margin-right: 0.6rem;
  }
`

export const SignButton = styled(Button)`
  width: 11.625rem;
  height: 3rem;
  border-radius: 0.5rem;
`
