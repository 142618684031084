import React, { useEffect, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { DateField, FieldWrap, TextInput, TextArea, Select } from 'src/components';
import { useAppDispatch } from 'src/hooks';
import { INITIATIVE_REPORT_FIELDS } from 'src/constants/forms';
import { TextAreaBlock } from './styled';
import { removeInitiativeReport, updateInitiativeReport } from '../../../../redux/initiativeReport/actions';
import { formatIncidentAndInitiativeTypes } from 'src/helpers/dataFormat';
import moment from 'moment';
import { DeleteBlock } from '../DailyReport/styled';
import { DeleteOutlined } from '@ant-design/icons';
import { renderPercent } from '../../../../helpers/renderStrings';

const { DATE, VISITORS, TYPE, BOYS, BOYS_PERCENTS, GIRLS, GIRLS_PERCENTS, NON_GENDER, NON_GENDER_PERCENTS, CASHIER, BALANCE, DESCRIPTION } = INITIATIVE_REPORT_FIELDS


type TProps = {
  report: any,
  reportsLength: number,
  initiativeTypesList: any[],
}

export const InitiativeReportForm = ({ report, reportsLength, initiativeTypesList }: TProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { value, name } = e.target as HTMLTextAreaElement
    const isCounter = name === BOYS || name === GIRLS || name === NON_GENDER
    const reducedValue = isCounter ? (parseInt(value, 10) || 0) : value
    dispatch(updateInitiativeReport({ isCounter, value: reducedValue, name, id: report.id }))
  }
  const onFieldChange = (name: string, value: any) => {
    dispatch(updateInitiativeReport({ isCounter: false, value, name, id: report.id }))
  }

  const onRemoveInitiativeReport = () => {
    dispatch(removeInitiativeReport({ id: report.id }))
  }

  useEffect(() => {
    const containCurrentType = initiativeTypesList?.some((i: any) => i.id === report[TYPE]);
    if (report[TYPE] && !containCurrentType) {
      dispatch(updateInitiativeReport({  isCounter: false, id: report.id, value: '', name: TYPE }))
    }
  }, [initiativeTypesList])

  return <Formik
    initialValues={report}
    onSubmit={(v) => console.log('values', v)}
  >
    {({ handleChange, handleSubmit }) => {
      return <>
      {reportsLength !== 1 &&<DeleteBlock>
				<button className="delete" onClick={onRemoveInitiativeReport}>
					<DeleteOutlined/>
					<span>Radera</span>
				</button>
			</DeleteBlock>}
      <form onSubmit={handleSubmit} noValidate onChange={onFormChange}>
        <FieldWrap>
          <DateField
            name={DATE}
            value={report[DATE] ? moment(report[DATE]) : moment()}
            handleChange={onFieldChange}
          />
        </FieldWrap>
        <FieldWrap label={t('initiativeReport.choose_initiative_type')}>
          <Select
            list={formatIncidentAndInitiativeTypes(initiativeTypesList)}
            name={TYPE}
            handleChange={onFieldChange}
            value={report[TYPE]}
            placeholder={t('initiativeReport.choose_initiative_type')}
          />
        </FieldWrap>
        <FieldWrap label={t('initiativeReport.local_visitors_info')}>
          <TextInput
            type="text"
            name={VISITORS}
            placeholder={t('dailyReport.digital_visitors_placeholder')}
            handleChange={handleChange}
            value={report[VISITORS]}
            disabled
          />
        </FieldWrap>
        {/*<FieldWrap label={t('dailyReport.digital_visitors_info')}>*/}
        {/*  <TextInput*/}
        {/*    type="text"*/}
        {/*    name={DIGITAL_VISITORS}*/}
        {/*    placeholder={t('dailyReport.digital_visitors_placeholder')}*/}
        {/*    handleChange={handleChange}*/}
        {/*    value={report[DIGITAL_VISITORS]}*/}
        {/*    disabled*/}
        {/*  />*/}
        {/*</FieldWrap>*/}
        <FieldWrap label={t('initiativeReport.guys_info')}>
          <TextInput
            type="text"
            name={BOYS}
            placeholder={t('dailyReport.guys_placeholder')}
            handleChange={handleChange}
            value={report[BOYS]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.guys_percent_info')}>
          <TextInput
            type="text"
            name={BOYS_PERCENTS}
            placeholder={t('dailyReport.guys_percent_placeholder')}
            handleChange={handleChange}
            value={renderPercent(report[BOYS_PERCENTS]?.toFixed())}
            disabled
          />
        </FieldWrap>
        <FieldWrap label={t('initiativeReport.girls_info')}>
          <TextInput
            type="text"
            name={GIRLS}
            placeholder={t('dailyReport.girls_placeholder')}
            handleChange={handleChange}
            value={report[GIRLS]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.girls_percent_info')}>
          <TextInput
            type="text"
            name={GIRLS_PERCENTS}
            placeholder={t('dailyReport.girls_percent_placeholder')}
            handleChange={handleChange}
            value={renderPercent(report[GIRLS_PERCENTS]?.toFixed())}
            disabled
          />
        </FieldWrap>
        <FieldWrap label={t('initiativeReport.non_gender_info')}>
          <TextInput
            type="text"
            name={NON_GENDER}
            placeholder={t('dailyReport.non_gender_placeholder')}
            handleChange={handleChange}
            value={report[NON_GENDER]}
          />
        </FieldWrap>
        <FieldWrap label={t('dailyReport.non_gender_percent_info')}>
          <TextInput
            type="text"
            name={NON_GENDER_PERCENTS}
            placeholder={t('dailyReport.non_gender_percent_placeholder')}
            handleChange={handleChange}
            value={renderPercent(report[NON_GENDER_PERCENTS]?.toFixed())}
            disabled
          />
        </FieldWrap>
        <TextAreaBlock>
          <TextArea
            name={DESCRIPTION}
            handleChange={onFieldChange}
            value={report[DESCRIPTION]}
            placeholder={t('dailyReport.description')}
          />
        </TextAreaBlock>
      </form>
      </>
    }}
  </Formik>
}
