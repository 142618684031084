import styled from 'styled-components'
import ReactQuill from 'react-quill';
import { COLORS } from 'src/constants/colors';

export const StyledReactQuill = styled(ReactQuill)`
  .ql-editor {
    min-height: 96px;
    padding: 16px 32px;
  }
  .ql-editor.ql-blank {
    p {
      font-family: Roboto, sans-serif;
      font-size: 18px;
      color: ${COLORS.FONT_GREY}
    }
    &:focus::before {
      content: '';
    }
  }
`
