import { IDailyReport, IGetReportsDTO } from './types';
import { Moment } from 'moment';

export const SET_ERROR = '[common] DAILY_SET_ERROR'
export const SET_LOADING = '[common] DAILY_SET_LOADING'
export const SET_CURRENT_CLUB = '[common] SET_CURRENT_CLUB'
export const SET_CLUBS_LIST = '[common] SET_CLUBS_LIST'
export const SET_DATES_RANGE = '[common] SET_DATES_RANGE'
export const GET_CLUBS_LIST = '[common] GET_CLUBS_LIST'
export const SET_REPORTS_STATISTIC = '[common] SET_REPORTS_STATISTIC'
export const DELETE_REPORT = '[common] DELETE_REPORT'
export const SET_REPORTS_LIST = '[common] SET_REPORTS_LIST'
export const GET_REPORTS_LIST = '[common] GET_REPORTS_LIST'
export const GET_REPORTS_STATISTIC = '[common] GET_REPORTS_STATISTIC'
export const UPDATE_CURRENT_PAGE = '[common] UPDATE_CURRENT_PAGE'
export const GET_REPORT_PDF = '[common] GET_REPORT_PDF'
export const SET_NOTIFICATION = '[common] SET_NOTIFICATION'
export const SET_IS_REPORT_DELETED = '[common] SET_IS_REPORT_DELETED'

export const setLoading = (data: boolean) => ({ type: SET_LOADING, data })
export const setCurrentClub = (data: string | undefined) => ({ type: SET_CURRENT_CLUB, data })
export const setError = (data: string | null) => ({ type: SET_ERROR, data })
export const setClubsList = (data: any[]) => ({ type: SET_CLUBS_LIST, data })
export const setDatesRange = (data: Moment[]) => ({ type: SET_DATES_RANGE, data })
export const getClubsList = () => ({ type: GET_CLUBS_LIST })
export const getReportsList = (data: IGetReportsDTO) => ({ type: GET_REPORTS_LIST, data })
export const getReportsStatistic = (data: IGetReportsDTO) => ({ type: GET_REPORTS_STATISTIC, data })
export const setReportsList = (data: IDailyReport[]) => ({ type: SET_REPORTS_LIST, data })
export const setReportsStatistic = (data: any) => ({ type: SET_REPORTS_STATISTIC, data })
export const deleteReport = (data: string | undefined) => ({ type: DELETE_REPORT, data })
export const setCurrentPage = (data: any) => ({ data, type: UPDATE_CURRENT_PAGE })
export const getReportPdf = () => ({ type: GET_REPORT_PDF })
export const setNotification = (data: string | null) => ({ data, type: SET_NOTIFICATION })
export const setIsReportDeleted = (data: boolean) => ({ type: SET_IS_REPORT_DELETED, data })

