import { put, call, takeLatest } from 'redux-saga/effects';
import {
  GET_INCIDENT_TYPES_LIST,
  GET_STAFF_LIST,
  setError,
  setIncidentTypesList,
  setLoading,
  setStaffList,
} from './actions';
import { IncidentReportApi } from 'src/api';
import {TAction} from "@types";

export function* watchIncidentReport() {
  yield takeLatest(GET_STAFF_LIST, getStaffListAsync)
  yield takeLatest(GET_INCIDENT_TYPES_LIST, getIncidentTypesListAsync)
}

export function* getStaffListAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))

  try {
    const { data } = yield call(IncidentReportApi.getStaffList)
    yield put(setStaffList(data))
  } catch (err) {
    yield put(setError(err))
    console.log('getStaffListAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

export function* getIncidentTypesListAsync(action: TAction) {
  yield put(setLoading(true))
  yield put(setError(null))
  try {
    const { data } = yield call(IncidentReportApi.getIncidentTypesList, action.data)
    yield put(setIncidentTypesList(data))
  } catch (err) {
    yield put(setError(err))
    console.log('getIncidentTypesListAsync ERR - ', err);
  } finally {
    yield put(setLoading(false))
  }
}

