import React from 'react';
import { Form } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import {
  FieldLabel,
  LoginWrapper,
  SignButton,
  SignForm,
  SignInput,
  FieldWrap,
  SignTitle
} from './styled';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_FIELDS } from '../../constants/forms';
import { signIn } from '../../redux/auth/actions';
import { useDispatch } from 'react-redux';
import { passwordValidation } from '../../constants/validations';
import { useAppSelector } from 'src/hooks';
import { commonReducerSelector } from '../../redux/common';
import { ErrorPopup } from '../common/ErrorPopup';


const { EMAIL, PASSWORD, } = LOGIN_FORM_FIELDS

const AuthForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleSubmit = (values: any) => {
    dispatch(signIn(values))
  };

  const { error } = useAppSelector(commonReducerSelector);

  return (
    <LoginWrapper>
      { error ? <ErrorPopup error={error} /> : null}
      <SignForm form={form} name={EMAIL} layout="inline" onFinish={handleSubmit}>
        <SignTitle>{t('auth.sign_in')}</SignTitle>
        <FieldWrap>
          <FieldLabel>{t('auth.your_email')}</FieldLabel>
          <Form.Item
            name={EMAIL}
            rules={[{
              required: true,
              type: 'email',
              message: t('auth.wrong_email_format')
            }]}
          >
            <SignInput prefix={<MailOutlined className="site-form-item-icon"/>}/>
          </Form.Item>
        </FieldWrap>
        <FieldWrap>
          <FieldLabel>{t('auth.your_password')}</FieldLabel>
          <Form.Item
            name={PASSWORD}
            rules={[{ ...passwordValidation }]}
          >
            <SignInput
              prefix={<LockOutlined className="site-form-item-icon"/>}
              type="password"
            />
          </Form.Item>
        </FieldWrap>
          <Form.Item shouldUpdate>
            <SignButton
              type="primary"
              htmlType="submit"
            >
              {t('auth.sign_in')}
            </SignButton>
          </Form.Item>
      </SignForm>
    </LoginWrapper>
  );
};

export default AuthForm
