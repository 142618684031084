import { useAppDispatch, useAppSelector } from './'
import { dailyReportReducerSelector } from '../redux/dailyReport';
import { setEditReportId } from '../redux/dailyReport/actions';

export const useEditMode = (id?: string | null ) => {
  const { editReportId } = useAppSelector(dailyReportReducerSelector)
  const isEditMode = !!editReportId
  const dispatch = useAppDispatch()
  const updateEditReportId = () => {
    if (id !== undefined) {
      dispatch(setEditReportId(id))
    }
  }

  return { isEditMode, editReportId, updateEditReportId }
}
