import styled from 'styled-components'
import { COLORS } from 'src/constants';

type TProps = {
    bgColor?: string,
    color?: string,
}

export const NotificationWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 24px;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid ${COLORS.GREEN_LIGHT};
  background-color: ${COLORS.GREEN_LIGHTER};
  padding: 0.35em;
  border-radius: 8px;
  cursor: pointer;
`

export const NotificationText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.MAIN_TEXT};
  width: 100%;
  margin: 0 0 0 1em 
`

export const StyledCross = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.MAIN_TEXT};
  width: 100%;
`
